import React from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { colors, sizes, shadow } from "../../styles/theme.tsx";
import { ColorButton } from "../../components/Button.tsx";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const LinkStore = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
        }}
      >
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "90%", sm: 485, md: 760 },
            margin: "0 auto",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 28, sm: 32, md: 50 },
              color: colors.primary,
              textTransform: "uppercase",
              textAlign: "center",
            }}
            lineHeight={1.1}
            my={{ xs: 10, lg: "107px" }}
          >
            {t("linkStore.title")}
          </Typography>

          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 22, sm: 25, md: 31 },
              color: colors.primary,
              textTransform: "uppercase",
              textAlign: "center",
              marginBottom: 0.5,
            }}
            lineHeight={1}
          >
            {t("linkStore.descriptionP1")}
          </Typography>

          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 15, md: 18 },
              color: colors.gray,
              textAlign: "center",
            }}
            lineHeight={1}
          >
            {t("linkStore.descriptionP2")}
          </Typography>

          <Box
            sx={{
              width: { xs: "85%", sm: 306, md: 394 },
              marginTop: 2,
              marginBottom: 8,
            }}
          >
            <img
              src={require("../../assets/image/LogoTienda2.png")}
              alt={""}
              loading="lazy"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 3 }}>
              <img
                src={require("../../assets/image/AppStore.png")}
                alt={""}
                loading="lazy"
                style={{
                  objectFit: "contain",
                  width: 400,
                  height: 100,
                  cursor: "not-allowed",
                  opacity: .5
                }}
                onClick={() => window.open("", "_blank")}
                title="Disponible pronto"
              />
              <img
                src={require("../../assets/image/GooglePlay.png")}
                alt={""}
                loading="lazy"
                style={{
                  width: 400,
                  height: 100,
                  objectFit: "contain",
                  cursor: "pointer"
                }}
                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.finova_app.Finova&pcampaignid=web_share", "_blank")}
              />
            </Box>
          </Box>
          <ColorButton
            variant="contained"
            sx={{
              fontSize: { xs: 19, md: 23 },
              width: { xs: "60%", sm: 228, md: 287 },
            }}
            onClick={() => navigate("/sign-up")}
          >
            {t("common.requestHere")}
          </ColorButton>
          <Box mt={14} mb={30} width={"100%"}>
            <Grid
              container
              spacing={1.8}
              sx={{ height: "auto", alignItems: "stretch" }}
            >
              <Grid
                size={12}
                sx={{
                  background: colors.bone,
                  minHeight: 110,
                  borderRadius: "18px",
                  boxShadow: shadow.black,
                  padding: { xs: "10px 20px", md: "10px 40px" },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: 5,
                    height: 73,
                    background: colors.darkGreen,
                    position: "absolute",
                    top: "50%",
                    left: -3,
                    borderRadius: 2,
                    transform: "translateY(-50%)",
                  }}
                />

                <Grid
                  container
                  width={"100%"}
                  height={"100%"}
                  spacing={{ xs: 2, sm: 3, md: 3.5 }}
                >
                  <Grid
                    size={{ xs: 2, md: 1 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box sx={{ width: 40, height: 40 }}>
                      <img
                        src={require("../../assets/image/Desembolso.webp")}
                        alt={""}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    size={{ xs: 10, md: 11 }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Box sx={{ flex: "1" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: 19, md: 21 },
                          color: colors.primary,
                        }}
                        lineHeight={1}
                      >
                        {t("linkStore.cardTitle1")}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: { xs: 16, md: 15 },
                          color: colors.gray,
                          marginTop: "6px",
                        }}
                        lineHeight={1}
                      >
                        {t("linkStore.cardDescription1")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                size={12}
                sx={{
                  background: colors.bone,
                  minHeight: 110,
                  borderRadius: "18px",
                  boxShadow: shadow.black,
                  padding: { xs: "10px 20px", md: "10px 40px" },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: 5,
                    height: 73,
                    background: colors.darkGreen,
                    position: "absolute",
                    top: "50%",
                    left: -3,
                    borderRadius: 2,
                    transform: "translateY(-50%)",
                  }}
                />

                <Grid
                  container
                  width={"100%"}
                  height={"100%"}
                  spacing={{ xs: 2, sm: 3, md: 3.5 }}
                >
                  <Grid
                    size={{ xs: 2, md: 1 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box sx={{ width: 40, height: 40 }}>
                      <img
                        src={require("../../assets/image/Chulo.webp")}
                        alt={""}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    size={{ xs: 10, md: 11 }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Box sx={{ flex: "1" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: 19, md: 21 },
                          color: colors.primary,
                        }}
                        lineHeight={1}
                      >
                        {t("linkStore.cardTitle2")}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: { xs: 16, md: 15 },
                          color: colors.gray,
                          marginTop: "6px",
                        }}
                        lineHeight={1}
                      >
                        {t("linkStore.cardDescription2")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                size={12}
                sx={{
                  background: colors.bone,
                  minHeight: 110,
                  borderRadius: "18px",
                  boxShadow: shadow.black,
                  padding: { xs: "10px 20px", md: "10px 40px" },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: 5,
                    height: 73,
                    background: colors.darkGreen,
                    position: "absolute",
                    top: "50%",
                    left: -3,
                    borderRadius: 2,
                    transform: "translateY(-50%)",
                  }}
                />

                <Grid
                  container
                  width={"100%"}
                  height={"100%"}
                  spacing={{ xs: 2, sm: 3, md: 3.5 }}
                >
                  <Grid
                    size={{ xs: 2, md: 1 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box sx={{ width: 40, height: 40 }}>
                      <img
                        src={require("../../assets/image/Subida.webp")}
                        alt={""}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    size={{ xs: 10, md: 11 }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Box sx={{ flex: "1" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: 19, md: 21 },
                          color: colors.primary,
                        }}
                        lineHeight={1}
                      >
                        {t("linkStore.cardTitle3")}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: { xs: 16, md: 15 },
                          color: colors.gray,
                          marginTop: "6px",
                        }}
                        lineHeight={1}
                      >
                        {t("linkStore.cardDescription3")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                size={12}
                sx={{
                  background: colors.bone,
                  minHeight: 110,
                  borderRadius: "18px",
                  boxShadow: shadow.black,
                  padding: { xs: "10px 20px", md: "10px 40px" },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    width: 5,
                    height: 73,
                    background: colors.darkGreen,
                    position: "absolute",
                    top: "50%",
                    left: -3,
                    borderRadius: 2,
                    transform: "translateY(-50%)",
                  }}
                />

                <Grid
                  container
                  width={"100%"}
                  height={"100%"}
                  spacing={{ xs: 2, sm: 3, md: 3.5 }}
                >
                  <Grid
                    size={{ xs: 2, md: 1 }}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box sx={{ width: 40, height: 40 }}>
                      <img
                        src={require("../../assets/image/Banco.webp")}
                        alt={""}
                        loading="lazy"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    size={{ xs: 10, md: 11 }}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Box sx={{ flex: "1" }}>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: { xs: 19, md: 21 },
                          color: colors.primary,
                        }}
                        lineHeight={1}
                      >
                        {t("linkStore.cardTitle4")}
                      </Typography>

                      <Typography
                        sx={{
                          fontSize: { xs: 16, md: 15 },
                          color: colors.gray,
                          marginTop: "6px",
                        }}
                        lineHeight={1}
                      >
                        {t("linkStore.cardDescription4")}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LinkStore;
