import React from "react";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import {
  isAdult,
  isATest,
  isNumeric,
  JSONToForm,
  requestSquematic,
} from "../../../common/CommonFunctions.tsx";
import { getValueStorage, saveValueStorage } from "../../../common/storage.ts";
import { VideoCameraRequestComponent } from "../../../constants/permissions.tsx";
import { Box, Typography } from "@mui/material";
import * as regex from "../../../common/regex.ts";
import FormInputProfile from "./ValidationSubComponents/FormInputProfile.tsx";
import FormsInputIds from "./ValidationSubComponents/FormsInputIds.tsx";
import FormsInputSelect from "./ValidationSubComponents/FormsInputSelect.tsx";
import FormsInputDate from "./ValidationSubComponents/FormsInputDate.tsx";
import FormsInputLocation from "./ValidationSubComponents/FormsInputLocation.tsx";
import FormsInputMoney from "./ValidationSubComponents/FormsInputMoney.tsx";
import Lottie from "lottie-react";
import { ColorButton } from "../../../components/Button.tsx";
import { colors } from "../../../styles/theme.tsx";
import { convertBase64ToFile } from "./PersonalInformation.tsx";
import { confirmationAlert, errorAlert } from "../../../common/alerts.tsx";
import { useTranslation } from "react-i18next";
import { IRegisterStepState } from "../RegisterComponent.tsx";
import { useNavigate } from "react-router-dom";
import {
  FormsInputName,
  FormsInputNumber,
} from "./ValidationSubComponents/FormsInput.tsx";

interface IObjForm {
  id: string;
  input: JSX.Element;
  name?: string;
}

export default function Validation({ setRegisterStep }: IRegisterStepState) {
  const { t } = useTranslation();
  const { getUser, setIsLoading, signIn } = React.useContext(AuthContext);
  const { registerForVideoCamera } = VideoCameraRequestComponent();
  const [changes, setChanges] = React.useState<any>({});
  const [valid, setValid] = React.useState<any>({});
  const [inputsEditable, setInputsEditable] = React.useState<IObjForm[]>([]);
  const [permissionPhoto, setPermissionPhoto] = React.useState(false);
  const [c110_rowid, setC110_rowid] = React.useState("");

  const fetchCameraAndSetupListener = async () => {
    const stream = await registerForVideoCamera({ setIsLoading });
    if (stream) {
      setPermissionPhoto(true);
    }
  };

  const getUserTemp = getValueStorage("authSession");
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);

      const res = await requestSquematic(
        "GET",
        "/api/app/update_request/get_update_request",
        {},
        getUserTemp.token
      );

      if (res && res.results) {
        // Validamos que el json contenga solicitud de Fotos
        const hasRequestPhoto = JSON.parse(res.results.c110_json).some(
          (item: any) => ["9", "10"].includes(item.id.toString())
        );

        if (hasRequestPhoto) {
          // Si incluye 9 o 10, pedimos permisos de cámara
          await fetchCameraAndSetupListener();
        } else {
          renderFields();
        }
      } else if (res && !res.results) {
        setIsLoading(false);
        let resConfirm = await confirmationAlert(
          "",
          "No tienes validaciones pendientes, ¿Deseas continuar?",
          "Continuar"
        );
        if (resConfirm) {
          const getUserTemp = getValueStorage("authSession");
          signIn({ ...getUserTemp, hasValidations: false });
          saveValueStorage("authSession", {
            ...getUserTemp,
            hasValidations: false,
          });
          let obj = {
            prevStep: 29,
            nextStep: 24,
            desc: "valida toda la informacion y se envia a espera",
          };
          saveValueStorage("registerStep", obj);
          if (setRegisterStep) setRegisterStep(obj);
        }
      }
    })();
  }, []);

  //Renderizar los campos para las validaciones de datos
  const renderFields = async () => {
    const dataFirst: any = await requestSquematic(
      "GET",
      "/api/app/update_request/get_selects",
      {},
      getUserTemp.token
    );

    if (dataFirst.results) {
      const res = await requestSquematic(
        "GET",
        "/api/app/update_request/get_update_request",
        {},
        getUserTemp.token
      );

      if (res && res.results) {
        setC110_rowid(res.results.c110_rowid);
        let test: any[] = [];
        await JSON.parse(res.results.c110_json).forEach(({ id }: any) =>
          mappingObj({
            setChanges,
            setIsLoading,
            setValid,
            dataFirst,
            changes,
            getUser,
          }).forEach((input) => {
            if (!input.name) {
              const separator = input.id.split("-");

              if (separator.some((sep) => sep === id.toString())) {
                test.push(input);
                return;
              }
            }

            if (input.id === id.toString()) {
              test.push(input);
              return;
            }
          })
        );

        setInputsEditable(
          test.filter(
            (obj, pos, arr) =>
              arr.map((mapObj) => mapObj.id).indexOf(obj.id) === pos
          )
        );
      } else if (res && !res.results) {
        let resConfirm = await confirmationAlert(
          "",
          "No tienes validaciones pendientes, ¿Deseas continuar?",
          "Continuar"
        );
        if (resConfirm) {
          const getUserTemp = getValueStorage("authSession");
          signIn({ ...getUserTemp, hasValidations: false });
          saveValueStorage("authSession", {
            ...getUserTemp,
            hasValidations: false,
          });
          let obj = {
            prevStep: 29,
            nextStep: 24,
            desc: "valida toda la informacion y se envia a espera",
          };
          saveValueStorage("registerStep", obj);
          if (setRegisterStep) setRegisterStep(obj);
        }
      }
    }
    setIsLoading(false);
  };

  // Si los permisos de camara estan activos, se visualizará el contenido del JSON.
  React.useEffect(() => {
    if (permissionPhoto) {
      renderFields();
    }
  }, [permissionPhoto]);

  //Validar los campos
  const validateData = () => {
    let response = true;
    const typeName = ["1", "2", "3", "17", "25", "32"];
    const typeNumber = ["23", "26", "33", "37"];
    const typeAddress = ["11", "12", "18", "21", "28", "35", "38"];
    const typeDate = ["7", "8"];

    Object.entries(valid).forEach(([key, value]) => {
      if (!value) {
        response = false;
        let message = "* El campo es obligatorio";

        if (typeName.includes(key + "") || typeAddress.includes(key + "")) {
          let element = document.getElementById(`errorM_${key}_name`);
          if (element) {
            element.style.display = "block";
            element.innerHTML = message;
          }
        }

        if (typeNumber.includes(key + "")) {
          let element = document.getElementById(`errorM_${key}_number`);
          if (element) {
            element.style.display = "block";
            element.innerHTML = message;
          }
        }

        if (typeDate.includes(key + "")) {
          let element = document.getElementById(`errorM_${key}_date`);
          if (element) {
            element.style.display = "block";
            element.innerHTML = message;
          }
        }

        //Validar foto pefil
        if (key + "" === "9" && !changes["9"]) {
          message = "* La foto de perfil es obligatoria";
        }
        //Validar fotos cedula
        if (
          key + "" === "10" &&
          (!changes["10"]?.photoIdFront || !changes["10"]?.photoIdBack)
        ) {
          message = "* Las fotos de la cédula son obligatorias";
        }
        //Validar ubicacion tercero
        if (key + "" === "13") {
          if (!changes["13"].department) {
            let element = document.getElementById(`errorM_${key}_department`);
            if (element) {
              element.style.display = "block";
              element.innerHTML = "* Selecciona un departamento";
            }
          }

          if (changes["13"].department && !changes["13"].city) {
            let element = document.getElementById(`errorM_${key}_city`);
            if (element) {
              element.style.display = "block";
              element.innerHTML = "* Selecciona una ciudad";
            }
          } else {
            // Si el departamento esta vacio, esconde el error de la ciudad para solamente mostrar el del departamento
            let element = document.getElementById(`errorM_${key}_city`);
            if (element) {
              element.style.display = "none";
            }
          }

          if (changes["13"].city && !changes["13"].neighborhood) {
            // Si la ciudad es bogota y la localidad esta vacia, esconde el error del barrio para solamente mostrar el de la localidad
            if (
              changes["13"].department + "" === "11" &&
              changes["13"].city + "" === "1" &&
              !changes["13"].locality
            ) {
              let element = document.getElementById(
                `errorM_${key}_neighborhood`
              );
              if (element) {
                element.style.display = "none";
              }
            } else {
              let element = document.getElementById(
                `errorM_${key}_neighborhood`
              );
              if (element) {
                element.style.display = "block";
                element.innerHTML = "* Selecciona un barrio";
              }
            }
          } else {
            // Si el ciudad esta vacia, esconde el error de la barrio para solamente mostrar el de la ciudad
            let element = document.getElementById(`errorM_${key}_neighborhood`);
            if (element) {
              element.style.display = "none";
            }
          }

          // Error si hay localidad
          if (
            changes["13"].department + "" === "11" &&
            changes["13"].city + "" === "1" &&
            !changes["13"].locality
          ) {
            let element = document.getElementById(`errorM_${key}_locality`);
            if (element) {
              element.style.display = "block";
              element.innerHTML = "* Selecciona una localidad";
            }
          }
        }

        //Validar ubicacion referencia familiar
        if (key + "" === "27") {
          if (!changes["27"].department) {
            let element = document.getElementById(`errorM_${key}_department`);
            if (element) {
              element.style.display = "block";
              element.innerHTML = "* Selecciona un departamento";
            }
          }
          if (changes["27"].department && !changes["27"].city) {
            let element = document.getElementById(`errorM_${key}_city`);
            if (element) {
              element.style.display = "block";
              element.innerHTML = "* Selecciona una ciudad";
            }
          } else {
            // Si el departamento esta vacio, esconde el error de la ciudad para solamente mostrar el del departamento
            let element = document.getElementById(`errorM_${key}_city`);
            if (element) {
              element.style.display = "none";
            }
          }
        }

        //Validar ubicacion referencia personal
        if (key + "" === "34") {
          if (!changes["34"].department) {
            let element = document.getElementById(`errorM_${key}_department`);
            if (element) {
              element.style.display = "block";
              element.innerHTML = "* Selecciona un departamento";
            }
          }
          if (!changes["34"].city) {
            let element = document.getElementById(`errorM_${key}_city`);
            if (element) {
              element.style.display = "block";
              element.innerHTML = "* Selecciona una ciudad";
            }
          }
        }

        let element = document.getElementById(`errorM_${key}`);
        if (element) {
          element.style.display = "block";
          element.innerHTML = message;
        }
      } else {
        let element = document.getElementById(`errorM_${key}`);
        if (element) {
          element.style.display = "none";
        }
      }
    });

    Object.entries(changes).forEach(([key, value]) => {
      if (typeName.includes(key + "") || typeAddress.includes(key + "")) {
        let errors = "";

        const stringValue = value as string;

        if (stringValue.trim() === "") {
          errors = "* El campo es obligatorio";
        } else if (
          !regex.nameCharacters.test(stringValue) &&
          !typeAddress.includes(key + "")
        ) {
          errors = "* Solo se permiten letras y sin espacios al final";
        } else if (
          typeAddress.includes(key + "") &&
          !regex.companyAdress.test(stringValue)
        ) {
          errors = "* El Formato ingresado es inválido";
        } else if (!regex.minThreeCharacters.test(stringValue)) {
          errors = "* Mínimo 3 caracteres";
        } else if (isATest(stringValue)) {
          errors = "* El campo no puede contener 'prueba' o 'admin'";
        }

        // Actualizar el mensaje de error dinámicamente
        const errorElement = document.getElementById(`errorM_${key}_name`);
        if (errorElement) {
          if (errors.length > 0) {
            errorElement.style.display = "block";
            errorElement.textContent = errors;
          } else {
            errorElement.style.display = "none";
            errorElement.textContent = ""; // Limpia el texto si no hay errores
          }
        }
      }

      if (typeNumber.includes(key + "")) {
        let errors = "";

        const stringValue = value as string;

        if (stringValue.trim() === "") {
          errors = "* El campo es obligatorio";
        } else if (!regex.minSevenCharacters.test(stringValue)) {
          errors = "* Mínimo 7 caracteres";
        }

        const errorElement = document.getElementById(`errorM_${key}_number`);
        if (errorElement) {
          if (errors.length > 0) {
            errorElement.style.display = "block";
            errorElement.textContent = errors;
          } else {
            errorElement.style.display = "none";
            errorElement.textContent = "";
          }
        }
      }

      if (typeDate.includes(key + "")) {
        let errors = "";

        const stringValue = value as string;

        if (stringValue.trim() === "") {
          errors = "* El campo es obligatorio";
        } else if (new Date(stringValue) > new Date()) {
          errors = "* La fecha no puede ser superior a hoy";
        } else if (key === "8" && !isAdult(new Date(stringValue))) {
          errors = "* Debes ser mayor de edad";
        }

        const errorElement = document.getElementById(`errorM_${key}_date`);
        if (errorElement) {
          if (errors.length > 0) {
            errorElement.style.display = "block";
            errorElement.textContent = errors;
          } else {
            errorElement.style.display = "none";
            errorElement.textContent = "";
          }
        }
      }
    });

    return response;
  };

  //Envio
  const handleUpdateData = async () => {
    if (!validateData()) {
      errorAlert("Error", t("validation.allComplete"), "");
      return true;
    }

    setIsLoading(true);
    const { c120_apellido1, c120_apellido2, c120_nombres, c120_rowid } =
      await getValueStorage("profileData");

    let c180_foto_cedula_frente: any = undefined;
    let c180_foto_cedula_atras: any = undefined;
    let c180_foto_perfil: any = undefined;

    if (changes["9"]) {
      c180_foto_perfil = convertBase64ToFile(
        changes["9"],
        `${c120_rowid}_selfie.jpg`
      );
    }

    if (changes["10"]) {
      c180_foto_cedula_frente = convertBase64ToFile(
        changes["10"].photoIdFront,
        `${c120_rowid}_cedulaA.jpg`
      );
      c180_foto_cedula_atras = convertBase64ToFile(
        changes["10"].photoIdBack,
        `${c120_rowid}_cedulaF.jpg`
      );
    }

    const objToSend = {
      c110_rowid,
      c120_rowid,
      c180_foto_perfil,
      c180_foto_cedula_frente,
      c180_foto_cedula_atras,
      c120_nombres: changes["1"],
      c120_apellido1: changes["2"],
      c120_apellido2: changes["3"],
      c120_razon_social:
        changes["1"] || changes["2"] || changes["3"]
          ? `${changes["1"] || c120_nombres} ${
              changes["2"] || c120_apellido1
            } ${changes["3"] || c120_apellido2}`
          : undefined,
      c120_ind_sexo: changes["4"],
      c120_ind_estado_civil: changes["5"],
      c120_fecha_expedicion_cedula: changes["7"],
      c120_fecha_nacimiento: changes["8"],
      c126_rowid_ocupacion: changes["16"],
      c126_cargo: changes["17"],
      c126_empresa: changes["18"],
      c126_periodo_pago: changes["19"],
      c126_ingresos: changes["20"],
      c126_id_banco: changes["22"],
      c126_nro_cuenta: changes["23"],
      c126_tipo_cuenta: changes["24"],
      c126_direccion_trabajo: changes["21"],
      c126_rowid_estadolaboral: changes["36"],
      c126_num_empresa: changes["37"],
      c126_otro_cargo: changes["38"],
      referencia_yo: JSON.stringify({
        c125_direccion1: changes["11"],
        c125_id_depto: changes["13"]?.department,
        c125_id_ciudad: changes["13"]?.city,
        c125_id_barrio: changes["13"]?.neighborhood,
        c125_id_pais: changes["13"]?.country,
        c125_descripcion_de_direccion: changes["12"],
      }),
      referencia_familiar: JSON.stringify({
        c125_direccion1: changes["28"],
        c125_id_depto: changes["27"]?.department,
        c125_id_ciudad: changes["27"]?.city,
        c125_id_pais: changes["27"]?.country,
        c125_celular: changes["26"],
        c125_contacto: changes["25"] ? "familiar-" + changes["25"] : undefined,
        c125_rowid_parentesco: changes["31"],
      }),
      referencia_personal: JSON.stringify({
        c125_direccion1: changes["35"],
        c125_id_depto: changes["34"]?.department,
        c125_id_ciudad: changes["34"]?.city,
        c125_id_pais: changes["34"]?.country,
        c125_celular: changes["33"],
        c125_contacto: changes["32"] ? "personal-" + changes["32"] : undefined,
      }),
    };

    const formData = JSONToForm(JSON.parse(JSON.stringify(objToSend)));

    const data: any = await requestSquematic(
      "POST",
      "/api/app/update_request/all_info_update",
      formData,
      getUserTemp.token
    );

    if (data) {
      let objSession = {
        ...getUserTemp,
        hasValidations: false,
        dashboard: false,
      };

      if (getUserTemp.loanActive?.c200_rowid) {
        objSession.dashboard = true;
        signIn(objSession);
        saveValueStorage("authSession", objSession);
        navigate("/");
      } else {
        signIn(objSession);
        saveValueStorage("authSession", objSession);
        let obj = {
          prevStep: 29,
          nextStep: 24,
          desc: "valida toda la informacion y se envia a espera",
        };
        saveValueStorage("registerStep", obj);
        if (setRegisterStep) setRegisterStep(obj);
      }
    }

    setIsLoading(false);
  };

  // Diseño principal
  return (
    <>
      <Box>
        <Box>
          <Typography
            sx={{
              textAlign: "center",
              color: colors.primary,
              fontWeight: 700,
              fontSize: 22,
              lineHeight: 1,
              textTransform: "uppercase",
              mb: 1,
            }}
          >
            {t("validation.requestData")}
          </Typography>
          <Typography>{t("validation.ups")}</Typography>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie
              style={{
                width: 250,
                height: 250,
              }}
              loop={false}
              autoPlay
              animationData={require("../../../assets/animations/validarDatos.json")}
            />
          </div>
          <Typography>
            {t("validation.forContinue")}{" "}
            {inputsEditable.length > 0
              ? inputsEditable.length > 2
                ? t("validation.continue1")
                : t("validation.continue2")
              : null}
          </Typography>
        </Box>
        <Box marginBottom={3}>
          {inputsEditable
            .filter(({ input }) => input)
            .map(({ input }, key) => (
              <Box key={key}>{input}</Box>
            ))}
        </Box>
        <Box>
          <Typography>{t("validation.sure")}</Typography>
          <ColorButton
            onClick={handleUpdateData}
            disabled={getUser.isLoading}
            sx={{
              display: "block",
              marginTop: 3,
              width: { xs: "100%", sm: 245 },
              // position: "fixed",
              // bottom: 10,
              // left: 180,
            }}
          >
            {t("common.send")}
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}

// Render de inputs
function mappingObj({
  setChanges,
  setIsLoading,
  setValid,
  dataFirst,
  changes,
  getUser,
}): IObjForm[] {
  return [
    {
      id: "9-",
      input: <Separator name="validation.faceInfo" />,
    },
    {
      id: "9",
      input: (
        <FormInputProfile
          setChanges={setChanges}
          id={"9"}
          isLoading={getUser.isLoading}
          setValids={setValid}
        />
      ),
    },
    {
      id: "10-",
      input: <Separator name="validation.docInfo" />,
    },
    {
      id: "10",
      input: (
        <FormsInputIds setChanges={setChanges} id={"10"} setValids={setValid} />
      ),
    },
    {
      id: "1-2-3-4-5-6-7-8",
      input: <Separator name="validation.personalInfo" />,
    },
    {
      id: "1",
      input: (
        <FormsInputName
          nameParam="personalInformation.names"
          setChanges={setChanges}
          id={"1"}
          isLoading={false}
          condition={(x: string) =>
            regex.nameCharacters.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "2",
      input: (
        <FormsInputName
          nameParam="personalInformation.firstLastName"
          setChanges={setChanges}
          id={"2"}
          isLoading={false}
          condition={(x: string) =>
            regex.nameCharacters.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "3",
      input: (
        <FormsInputName
          nameParam="personalInformation.secondLastName"
          setChanges={setChanges}
          id={"3"}
          isLoading={false}
          condition={(x: string) =>
            regex.nameCharacters.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "4",
      input: (
        <FormsInputSelect
          nameParam="personalInformation.sex"
          setChanges={setChanges}
          id={"4"}
          isLoading={false}
          internValue="c006_descripcion"
          keyValue="c006_valor"
          options={dataFirst.results?.get_personal_information.sex_indicator}
          setValids={setValid}
        />
      ),
    },
    {
      id: "5",
      input: (
        <FormsInputSelect
          nameParam="personalInformation.civilStatus"
          setChanges={setChanges}
          id={"5"}
          isLoading={false}
          internValue="c006_descripcion"
          keyValue="c006_valor"
          options={dataFirst.results?.get_personal_information.marital_status}
          setValids={setValid}
        />
      ),
    },
    {
      id: "6",
      input: (
        <FormsInputName
          nameParam="idUpload.numId"
          setChanges={setChanges}
          id={"6"}
          maxLength={10}
          isLoading={false}
          condition={(x: string) =>
            isNumeric(x) && x.length >= 6 && x.length <= 12
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "7",
      input: (
        <FormsInputDate
          nameParam="idUpload.dateExpedition"
          setChanges={setChanges}
          id={"7"}
          isLoading={false}
          condition={(x: string) => x !== ""}
          setValids={setValid}
        />
      ),
    },
    {
      id: "8",
      input: (
        <FormsInputDate
          nameParam="personalInformation.birthDate"
          setChanges={setChanges}
          id={"8"}
          isLoading={false}
          condition={(x: string) => x !== ""}
          setValids={setValid}
        />
      ),
    },
    {
      id: "11-12-13-15",
      input: <Separator name="validation.contactInfo" />,
    },
    {
      id: "11",
      input: (
        <FormsInputName
          nameParam="contactInformation.mapLabel"
          setChanges={setChanges}
          id={"11"}
          isLoading={false}
          condition={(x: string) =>
            regex.companyAdress.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "12",
      input: (
        <FormsInputName
          nameParam="contactInformation.dirDesc"
          setChanges={setChanges}
          id={"12"}
          isLoading={false}
          condition={(x: string) =>
            regex.companyAdress.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "13",
      input: (
        <FormsInputLocation
          names={[
            "contactInformation.country",
            "contactInformation.department",
            "contactInformation.city",
            "contactInformation.locality",
            "contactInformation.neighborhood",
          ]}
          setChanges={setChanges}
          id={"13"}
          isLoading={false}
          setValids={setValid}
          setIsLoading={setIsLoading}
          token={getUser.token}
        />
      ),
    },
    {
      id: "16-17-18-19-20-21",
      input: <Separator name="validation.financialInfo" />,
    },
    {
      id: "16",
      input: (
        <FormsInputSelect
          nameParam="financialInformation.ocupation"
          setChanges={setChanges}
          id={"16"}
          isLoading={false}
          internValue="c016_descripcion"
          keyValue="c016_rowid"
          options={dataFirst.results?.occupation_classification}
          setValids={setValid}
        />
      ),
    },
    {
      id: "17",
      input: (
        <FormsInputName
          nameParam="financialInformation.charge"
          setChanges={setChanges}
          id={"17"}
          isLoading={false}
          condition={(x: string) =>
            regex.nameCharacters.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "18",
      input: (
        <FormsInputName
          nameParam="financialInformation.company"
          setChanges={setChanges}
          id={"18"}
          isLoading={false}
          condition={(x: string) =>
            regex.companyAdress.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "19",
      input: (
        <FormsInputSelect
          nameParam="financialInformation.periodPay"
          setChanges={setChanges}
          id={"19"}
          isLoading={false}
          internValue="c054_descripcion"
          keyValue="c054_rowid"
          options={dataFirst.results?.period_types.filter(
            (item) => item.c054_estado === "1"
          )}
          setValids={setValid}
        />
      ),
    },
    {
      id: "20",
      input: (
        <FormsInputMoney
          nameParam="financialInformation.salary"
          setChanges={setChanges}
          id={"20"}
          isLoading={false}
          setValids={setValid}
        />
      ),
    },
    {
      id: "21",
      input: (
        <FormsInputName
          nameParam="financialInformation.companyDir"
          setChanges={setChanges}
          id={"21"}
          isLoading={false}
          condition={(x: string) =>
            regex.companyAdress.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "22-23-24",
      input: <Separator name="validation.disbursementInformation" />,
    },
    {
      id: "22",
      input: (
        <FormsInputSelect
          nameParam="bankInformation.selectBank"
          setChanges={setChanges}
          id={"22"}
          isLoading={false}
          internValue="c021_descripcion"
          keyValue="c021_id"
          options={dataFirst.results?.banks}
          setValids={setValid}
        />
      ),
    },
    {
      id: "23",
      input: (
        <FormsInputNumber
          nameParam="bankInformation.numberAccount"
          setChanges={setChanges}
          id={"23"}
          isLoading={false}
          condition={(x: string) =>
            isNumeric(x) && regex.minSevenCharacters.test(x)
          }
          setValids={setValid}
          maxLength={20}
        />
      ),
    },
    {
      id: "24",
      input: (
        <FormsInputSelect
          nameParam="bankInformation.typeAccount"
          setChanges={setChanges}
          id={"24"}
          isLoading={false}
          internValue="c006_descripcion"
          keyValue="c006_valor"
          options={dataFirst.results?.financial_data_type}
          setValids={setValid}
        />
      ),
    },
    {
      id: "25-26-27-28-31",
      input: <Separator name="validation.refFamInfo" />,
    },
    {
      id: "25",
      input: (
        <FormsInputName
          nameParam="famRef.name"
          setChanges={setChanges}
          id={"25"}
          isLoading={false}
          condition={(x: string) =>
            regex.nameCharacters.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "26",
      input: (
        <FormsInputNumber
          nameParam="famRef.cel"
          setChanges={setChanges}
          id={"26"}
          maxLength={10}
          isLoading={false}
          condition={(x: string) =>
            isNumeric(x) && regex.minSevenCharacters.test(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "27",
      input: (
        <FormsInputLocation
          names={["famRef.country", "famRef.department", "famRef.city"]}
          setChanges={setChanges}
          id={"27"}
          isLoading={false}
          setIsLoading={setIsLoading}
          setValids={setValid}
          token={getUser.token}
        />
      ),
    },
    {
      id: "28",
      input: (
        <FormsInputName
          nameParam="famRef.dir"
          setChanges={setChanges}
          id={"28"}
          isLoading={false}
          condition={(x: string) =>
            regex.companyAdress.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "31",
      input: (
        <FormsInputSelect
          nameParam="famRef.parent"
          setChanges={setChanges}
          id={"31"}
          isLoading={false}
          internValue="c006_descripcion"
          keyValue="c006_valor"
          options={dataFirst.results?.relationship}
          setValids={setValid}
        />
      ),
    },
    {
      id: "32-33-34-35",
      input: <Separator name="validation.refPersonalInfo" />,
    },
    {
      id: "32",
      input: (
        <FormsInputName
          nameParam="perRef.name"
          setChanges={setChanges}
          id={"32"}
          isLoading={false}
          condition={(x: string) =>
            regex.nameCharacters.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "33",
      input: (
        <FormsInputNumber
          nameParam="perRef.cel"
          setChanges={setChanges}
          id={"33"}
          maxLength={10}
          isLoading={false}
          condition={(x: string) =>
            isNumeric(x) && regex.minSevenCharacters.test(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "34",
      input: (
        <FormsInputLocation
          names={["perRef.country", "perRef.department", "perRef.city"]}
          setChanges={setChanges}
          id={"34"}
          isLoading={false}
          setIsLoading={setIsLoading}
          setValids={setValid}
          token={getUser.token}
        />
      ),
      name: "Ubicación",
    },
    {
      id: "35",
      input: (
        <FormsInputName
          nameParam="perRef.dir"
          setChanges={setChanges}
          id={"35"}
          isLoading={false}
          condition={(x: string) =>
            regex.companyAdress.test(x) &&
            regex.minThreeCharacters.test(x) &&
            !isATest(x)
          }
          setValids={setValid}
        />
      ),
    },
    {
      id: "36",
      input: (
        <FormsInputSelect
          nameParam="financialInformation.singleTitle"
          setChanges={setChanges}
          id={"36"}
          isLoading={false}
          internValue="c019_descripcion"
          keyValue="c019_rowid"
          options={dataFirst.results?.laboral_status}
          setValids={setValid}
        />
      ),
      name: "Estado laboral",
    },
    {
      id: "37",
      input: (
        <FormsInputNumber
          nameParam="financialInformation.companyTel"
          setChanges={setChanges}
          id={"37"}
          maxLength={12}
          isLoading={false}
          condition={(x: string) =>
            isNumeric(x) && regex.minSevenCharacters.test(x)
          }
          setValids={setValid}
        />
      ),
      name: "Teléfono empresa",
    },
    {
      id: "38",
      input: (
        <FormsInputName
          nameParam="financialInformation.otherCharge"
          setChanges={setChanges}
          id={"38"}
          isLoading={false}
          condition={(x: string) =>
            regex.companyAdress.test(x) && regex.minThreeCharacters.test(x)
          }
          setValids={setValid}
        />
      ),
      name: "Otro cargo",
    },
  ];
}

// Divider
function Separator({ name }) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        marginTop: 4,
        borderTop: `1px solid ${colors.primary}`,
        paddingTop: 4,
      }}
    >
      <Typography
        sx={{
          color: colors.primary,
          fontWeight: 700,
          fontSize: 16,
          lineHeight: 1,
          textTransform: "uppercase",
        }}
      >
        {t(name)}
      </Typography>
    </Box>
  );
}
