import React from "react";
import {
  DOMAIN,
  formatNumberPoint,
  requestSquematic,
} from "../../../../common/CommonFunctions.tsx";
import { AuthContext } from "../../../../hooks/useAuth.tsx";
import { getValueStorage } from "../../../../common/storage.ts";
import { errorAlert, infoAlert, MySwal } from "../../../../common/alerts.tsx";
import { CssTextField } from "../../../../components/CssTextField.tsx";
import { FormHelperText, InputAdornment } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useTranslation } from "react-i18next";
import { RemoveRedEyeIcon, VisibilityOffIcon, DeleteIcon } from "../../assets/iconsMui.tsx";

export default function Profile() {
  const { t } = useTranslation();
  const { getUser, setIsLoading } = React.useContext(AuthContext);
  const { c120_apellido1, c120_apellido2, c120_nombres, email, c120_nit } =
    getValueStorage("profileData");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] =
    React.useState(false);

  const [passwordMatchError, setPasswordMatchError] = React.useState(false);
  /**
   * step 0 = habilitar el cambio de contraseña
   * step 1 = enviar otp y verificar
   * step 2 = ingresar contraseñas y actualizar
   */
  const [step, setStep] = React.useState<number>(0);
  const [otpCode, setOtpCode] = React.useState<string>("");

  //Mostramos un modal para ver la foto de perfil
  const zoomProfile = async () => {
    setIsLoading(true);
    MySwal.fire({
      title: "<div class='text-primary text-xl uppercase'>Foto de perfil</div>",
      html: (
        <div className="w-full h-full flex justify-center">
          <img
            src={`${DOMAIN}/${getUser.photo}`}
            alt=""
            className="h-[250px] w-[250px] border-double border-4 border-primary"
            onLoad={() => setIsLoading(false)}
            style={{
              display: getUser.isLoading ? "none" : "block",
              borderRadius: 8,
              objectFit: "contain",
            }}
            onError={(e) => {
              e.currentTarget.src = require("../../../../assets/image/user.webp");
            }}
          />
        </div>
      ),
      showCloseButton: true,
      showConfirmButton: false,
      customClass: {
        popup: "bg-snow dark:bg-navy-800",
        htmlContainer: "!px-3 !overflow-x-hidden",
      },
    });
  };

  //Cambiar la visibilidad del campo de contraseña
  const handleClickShowPassword = (visibility: boolean, idElement: string) => {
    if (idElement === "new_password") {
      setVisiblePassword(visibility);
    } else {
      setVisibleConfirmPassword(visibility);
    }
    let element = document.getElementById(idElement) as HTMLInputElement;
    if (element) {
      element.type = visibility ? "text" : "password";
    }
  };

  //Enviar codigo OTP SMS
  const handleSendOtpSMS = async () => {
    if (!email || !email.value) {
      errorAlert("Error", "El correo electronico no puede estar vacío.", "");
      return true;
    }
    setIsLoading(true);

    const data = await requestSquematic(
      "POST",
      "/api/app/validation_otp/reset_password_send_otp_sms",
      {
        email: email.value,
        phone: getUser.phone.replace("+57", ""),
        code: "+57",
        c001_cod_lenguaje: "1",
      },
      getUser.token
    );

    if (data) {
      setStep(1);
    }

    setIsLoading(false);
  };

  //Validar codigo OTP
  const handleValidateOtp = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "POST",
      "/api/app/validation_otp/validate_otp",
      {
        otp_code: otpCode,
        value: getUser.phone,
      },
      getUser.token
    );

    if (data) {
      setStep(2);
    }
    setIsLoading(false);
  };

  //Cambiar contraseña
  const handleChangePassword = async () => {
    setIsLoading(true);

    const res = await requestSquematic(
      "POST",
      "/api/app/user/update_password",
      {
        password: password,
        email: email.value,
      }
    );

    if (res) {
      infoAlert("Contraseña modificada", "");
      setStep(0);
      setPassword("");
      setConfirmPassword("");
      setOtpCode("");
    }
    setIsLoading(false);
  };

  return (
    <div className="mx-1 md:mx-3 flex flex-col flex-1 md:h-[80vh] mb-12 md:mb-0">
      <section
        className={
          "relative  grid grid-cols-1 md:grid-cols-2 flex rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none w-full lg:w-[800px] 3xl:w-[1000px] px-6 py-8 bg-cover m-auto items-center justify-center gap-8"
        }
      >
        <div
          className="flex flex-col items-center flex-1 "
          id="contact_info_container"
        >
          {/* Background and profile */}
          <div
            className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
            style={{
              backgroundImage: `url(${require("../../assets/banner.png")})`,
            }}
          >
            <div className="absolute -bottom-12 flex h-[150px] w-[150px] items-center justify-center rounded-full border-[4px] border-white bg-lightPrimary dark:!border-navy-700">
              <img
                className="h-full w-full rounded-full cursor-zoom-in"
                style={{objectFit: "contain"}}
                src={DOMAIN + "/" + getUser.photo}
                alt=""
                onClick={zoomProfile}
                title="Ver foto de perfil"
                onError={(e) => {
                  e.currentTarget.src = require("../../../../assets/image/user.webp");
                }}
              />
            </div>
          </div>

          {/* Personal info labels */}
          <div className="mt-16 flex flex-col gap-4 w-[90%] ">
            <div className="flex flex-col">
              <p className="text-base lg:text-md font-semibold text-primary leading-5 lg:leading-4">
                {t("profile.name")}:
              </p>
              <p className="text-lg lg:text-xl text-secondary-900 dark:text-white leading-5 lg:leading-6 break-all">
                {c120_nombres}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-base lg:text-md font-semibold text-primary leading-5 lg:leading-4">
                {t("profile.lastNames")}:
              </p>
              <p className="text-lg lg:text-xl text-secondary-900 dark:text-white leading-5 lg:leading-6 break-all">
                {c120_apellido1} {c120_apellido2}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-base lg:text-md font-semibold text-primary leading-5 lg:leading-4">
                {t("profile.email")}:
              </p>
              <p className="text-lg lg:text-xl text-secondary-900 dark:text-white leading-5 lg:leading-6 break-all">
                {email?.value ?? ""}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-base lg:text-md font-semibold text-primary leading-5 lg:leading-4">
                {t("profile.phone")}:
              </p>
              <p className="text-lg lg:text-xl text-secondary-900 dark:text-white leading-5 lg:leading-6 break-all">
                {getUser.phone ?? ""}
              </p>
            </div>
            <div className="flex flex-col">
              <p className="text-base lg:text-md font-semibold text-primary leading-5 lg:leading-4">
                {t("profile.nit")}:
              </p>
              <p className="text-lg lg:text-xl text-secondary-900 dark:text-white leading-5 lg:leading-6 break-all">
                {formatNumberPoint(c120_nit)}
              </p>
            </div>
          </div>
        </div>

        <div
          id="password_change_cotainer"
          className="flex flex-col flex-1 px-4 sm:px-20 md:px-2 lg:px-8 3xl:px-16  mb-8 md:mb-0"
        >
          <p className="mb-2 text-xl font-bold text-primary dark:text-primary text-center md:text-left mt-5 md:mt-0 leading-5">
            {t("profile.changePassword")}
          </p>
          {step === 0 && (
            <button
              className="w-full sm:w-content rounded-lg bg-primary py-3 px-2 text-white font-bold text-base mb-[12px]
                  disabled:bg-secondary-400 disabled:cursor-not-allowed leading-5"
              onClick={handleSendOtpSMS}
            >
              {t("profile.habChange")}
            </button>
          )}
          {step === 1 && (
            <>
              <p className="mb-2 text-md text-secondary-900 dark:text-white text-center md:text-left">
                {t("profile.habChangeDescription")}
              </p>
              <CssTextField
                placeholder={t("profile.insertOtpCode")}
                id="otp_code"
                value={otpCode}
                sx={{
                  mt: 1,
                  input: {
                    textAlign: "center",
                    fontSize: 18,
                  },
                }}
                onChange={({ target }) => {
                  // Filtrar solo números
                  const filteredValue = target.value.replace(/[^0-9]/g, "");
                  target.value = filteredValue;
                  setOtpCode(filteredValue);
                }}
                inputProps={{
                  maxLength: 4, // Establece el máximo de caracteres aquí
                }}
              />
              <button
                className="w-full sm:w-content rounded-lg bg-primary py-3 px-2 text-white font-bold text-base mb-[12px]
                  disabled:bg-secondary-400 disabled:cursor-not-allowed leading-5"
                onClick={handleValidateOtp}
                disabled={!otpCode || otpCode.length < 4 || getUser.isLoading}
              >
                {t("profile.validateOtpCode")}
              </button>
            </>
          )}
          {step === 2 && (
            <>
              <CssTextField
                placeholder={t("profile.newPassword")}
                id="new_password"
                type="password"
                value={password}
                onChange={({ target }) => {
                  // Filtrar solo números
                  const filteredValue = target.value.replace(/[^0-9]/g, "");
                  target.value = filteredValue;
                  setPassword(filteredValue);
                  setPasswordMatchError(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {visiblePassword ? (
                        <RemoveRedEyeIcon
                          onClick={() =>
                            handleClickShowPassword(false, "new_password")
                          }
                          className="cursor-pointer"
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() =>
                            handleClickShowPassword(true, "new_password")
                          }
                          className="cursor-pointer"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 4, // Establece el máximo de caracteres aquí
                }}
                onBlur={() => {
                  // Verificar si las contraseñas coinciden
                  if (
                    password &&
                    confirmPassword &&
                    password !== confirmPassword
                  ) {
                    setPasswordMatchError(true);
                  } else {
                    setPasswordMatchError(false);
                  }
                }}
              />
              <CssTextField
                placeholder={t("profile.confirmPassword")}
                id="confirm_password"
                type="password"
                value={confirmPassword}
                onChange={({ target }) => {
                  // Filtrar solo números
                  const filteredValue = target.value.replace(/[^0-9]/g, "");
                  target.value = filteredValue;
                  setConfirmPassword(filteredValue);
                  setPasswordMatchError(false);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {visibleConfirmPassword ? (
                        <RemoveRedEyeIcon
                          onClick={() =>
                            handleClickShowPassword(false, "confirm_password")
                          }
                          className="cursor-pointer"
                        />
                      ) : (
                        <VisibilityOffIcon
                          onClick={() =>
                            handleClickShowPassword(true, "confirm_password")
                          }
                          className="cursor-pointer"
                        />
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  maxLength: 4, // Establece el máximo de caracteres aquí
                }}
                onBlur={() => {
                  // Verificar si las contraseñas coinciden
                  if (
                    password &&
                    confirmPassword &&
                    password !== confirmPassword
                  ) {
                    setPasswordMatchError(true);
                  } else {
                    setPasswordMatchError(false);
                  }
                }}
              />
              <button
                className="w-full sm:w-content rounded-lg bg-primary py-3 px-2 text-white font-bold text-base
                  disabled:bg-secondary-400 disabled:cursor-not-allowed leading-5"
                onClick={handleChangePassword}
                disabled={
                  (!password && !confirmPassword) ||
                  password !== confirmPassword ||
                  !otpCode ||
                  (password.length < 4 && confirmPassword.length < 4)
                }
              >
                {t("profile.changePassword")}
              </button>
              {passwordMatchError && (
                <FormHelperText
                  style={{
                    color: "red",
                    fontSize: 11,
                    width: "100%",
                    textAlign: "center",
                    lineHeight: 1.1,
                    whiteSpace: "wrap",
                  }}
                >
                  * {t("profile.notMatch")}
                </FormHelperText>
              )}
            </>
          )}
        </div>
      </section>
    </div>
  );
}
