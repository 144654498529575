import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Slider,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  IPaymentPlan,
  ISimulatorData,
  mockSelectBasic,
} from "../../constants/types";
import { colors } from "../../styles/theme.tsx";
import {
  formatNumberPoint,
  requestSquematic,
} from "../../common/CommonFunctions.tsx";
import { ColorButton } from "../Button.tsx";
import Lottie from "lottie-react";
import { getValueStorage, saveValueStorage } from "../../common/storage.ts";
import { useNavigate } from "react-router-dom";
import { IRegisterStep } from "../../pages/User/RegisterComponent.tsx";

const CustomSlider = styled(Slider)({
  color: colors.primary,
});

interface ISimulatorProps {
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  seeTitleForm?: boolean;
  isSimulationWebsite?: boolean;
}

export default function Simulator({
  setRegisterStep,
  seeTitleForm = false,
  isSimulationWebsite = false,
}: ISimulatorProps) {
  const navigate = useNavigate();
  const [isLoadingSimulator, setIsLoadingSimulator] =
    React.useState<boolean>(false);
  const { t } = useTranslation();
  // Obtener datos de simulacion del localstorage del home
  const simulationData: ISimulatorData =
    getValueStorage("simulationData") !== null && seeTitleForm // Si hay datos de simulacion y no esta en el home
      ? getValueStorage("simulationData")
      : "";
  const [data, setData] = React.useState<ISimulatorData>({
    c200_rowid: "",
    c200_valor: simulationData ? simulationData.c200_valor : "0",
    c200_rowid_ciiu: simulationData ? simulationData.c200_rowid_ciiu : "1",
    c200_plazo: simulationData ? simulationData.c200_plazo : "2", // semanal: 4, 8, 12, 16 - quincenal: 2, 4, 6, 8
    c200_rowid_concepto: simulationData
      ? simulationData.c200_rowid_concepto
      : "10",
    c200_rowid_periodo: simulationData
      ? simulationData.c200_rowid_periodo
      : "4", //1 semanal, 4 quincenal
    date_first_due: simulationData ? simulationData.date_first_due : "",
    due_value: simulationData ? simulationData.due_value : "0",
    total_due_to_pay: simulationData ? simulationData.total_due_to_pay : "0",
  });
  const [arrPlazo, setArrPlazo] = React.useState<mockSelectBasic[]>([]);
  const [dueValue, setDueValue] = React.useState<string>("0");
  const [valSlider, setValSlider] = React.useState({
    min: 500000,
    max: 6000000,
    frecuency: 100000,
  });

  const getUser = getValueStorage("authSession") !== null && getValueStorage("authSession");

  //Obtener la configuracion de la linea de credito
  const fetchSubcategories = async () => {
    let url = isSimulationWebsite
      ? "/api/website/sub_categories/find"
      : "/api/app/sub_categories/find";
    const res = await requestSquematic(
      "POST",
      url,
      { c128_rowid_concepto: data.c200_rowid_concepto },
      getUser.token
    );

    if (res && res.results) {
      const min = 500000;
      const max = parseFloat(res.results![0].c128_rango_hasta);
      const frecuency = parseFloat(res.results![0].c128_incremento);
      const cantDues = simulationData
        ? parseFloat(simulationData.c200_plazo)
        : parseFloat(res.results![0].c128_nro_cuotas);
      const amount =
        data.c200_valor === "0"
          ? Math.ceil(((max - min) / 2 + min) / 100000) * 100000
          : parseInt(data.c200_valor);

      setValSlider({ frecuency, min, max });
      handleChangePeriodicity(data.c200_rowid_periodo, cantDues + "");
      await handlerSimulator(
        amount,
        cantDues,
        parseInt(data.c200_rowid_periodo)
      );
    }
  };

  //Hace la simulacion del credito segun los parametros seleccionados
  const handlerSimulator = async (
    amount: number,
    cantDues: number,
    period: number
  ) => {
    if (amount === 0) return true;
    if (!isLoadingSimulator) setIsLoadingSimulator(true);

    let url = isSimulationWebsite
      ? "/api/website/loan/loan_simulate"
      : "/api/app/loan/loan_simulate";
    const res = await requestSquematic(
      "POST",
      url,
      {
        c200_valor: amount,
        c200_rowid_concepto: data.c200_rowid_concepto,
        c200_plazo: cantDues,
        c200_rowid_periodo: period,
        c200_rowid_ciiu: data.c200_rowid_ciiu,
        is_simulate_website: "true",
      },
      getUser.token
    );

    if (res && res.results) {
      const dueValue = res.results.additional_payment_plan.reduce(
        (sum, { c210_vlr_cuota }) => sum + parseInt(c210_vlr_cuota),
        parseInt(res.results.payment_plan[0].c202_vlr_cuota)
      );
      setDueValue(dueValue + "");

      //Extraer la cantidad de cuotas y organizarlas de menora a mayor para obtener la primera fecha de pago
      const duesToPay = res.results.payment_plan.sort(
        (a: IPaymentPlan, b: IPaymentPlan) =>
          parseInt(a.c202_rowid) - parseInt(b.c202_rowid)
      );

      //Calcular el valor total a pagar
      const totalDuesCapital = duesToPay.reduce(
        (sum, { c202_vlr_cuota }) => sum + parseInt(c202_vlr_cuota),
        0
      );
      const totalDuesInt = res.results.additional_payment_plan.reduce(
        (sum, { c210_vlr_cuota }) => sum + parseInt(c210_vlr_cuota),
        0
      );

      setData({
        ...data,
        c200_rowid_periodo: period + "",
        c200_valor: amount + "",
        c200_plazo: cantDues + "",
        date_first_due: duesToPay[0].c202_fecha_cuota,
        due_value: dueValue + "",
        total_due_to_pay: totalDuesCapital + totalDuesInt * cantDues + "",
      });
    }

    setIsLoadingSimulator(false);
  };

  React.useEffect(() => {
    (async () => {
      setIsLoadingSimulator(true);
      await fetchSubcategories();
    })();
  }, []);

  //Cambiar el valor del monto a solicitar
  const handleChangeDueValue = (newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setData({ ...data, c200_valor: newValue.toString() });
      handlerSimulator(
        newValue,
        parseInt(data.c200_plazo),
        parseInt(data.c200_rowid_periodo)
      );
    }
  };

  //Canbiar la periodicidad de pago
  const handleChangePeriodicity = (periodo: string, plazo: string) => {
    handlerSimulator(
      parseInt(data.c200_valor),
      parseInt(plazo),
      parseInt(periodo)
    );
    if (periodo === "1") {
      setArrPlazo([
        { id: "4", name: "4" },
        { id: "8", name: "8" },
        { id: "12", name: "12" },
        { id: "16", name: "16" },
      ]);
    } else {
      setArrPlazo([
        { id: "2", name: "2" },
        { id: "4", name: "4" },
        { id: "6", name: "6" },
        { id: "8", name: "8" },
      ]);
    }
  };

  //Cambiar la cantidad de cuotas
  const handleChangeCantDues = (cantDues: string) => {
    setData({ ...data, c200_plazo: cantDues });
    handlerSimulator(
      parseInt(data.c200_valor),
      parseInt(cantDues),
      parseInt(data.c200_rowid_periodo)
    );
  };

  //Tomar los datos de la simulacion y enviarlos al ConfirmLoan
  const handlePreSubmitSimulation = () => {
    saveValueStorage("simulationData", data);
    // Navgación desde el home
    if (!seeTitleForm) {
      navigate("/sign-up");
    } else {
      let objectStep: IRegisterStep = {
        prevStep: 10,
        nextStep: 11,
        desc: "el usuario hizo la simulacion",
      };

      saveValueStorage("registerStep", objectStep);

      if (setRegisterStep) setRegisterStep(objectStep);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        padding: { xs: "45px 25px", sm: "45px 50px" },
        textAlign: "center",
      }}
    >
      {seeTitleForm && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginBottom={5}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              color: colors.black,
              textTransform: "uppercase",
              marginBottom: "5px",
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("common.form")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 20,
              color: colors.primary,
              textTransform: "uppercase",
              marginBottom: "5px",
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("common.forRequestCredit")}
          </Typography>
        </Box>
      )}
      <Typography
        sx={{
          fontWeight: "600",
          fontSize: 25,
          color: colors.primary,
          marginTop: 1.5,
          mb: 4,
        }}
        lineHeight={1}
        textAlign={"center"}
      >
        {t("home.simulatorTitle").split("-")[0]} <br />
        <span style={{ color: colors.primary, fontWeight: "bold" }}>
          {t("home.simulatorTitle").split("-")[1]}
        </span>
      </Typography>
      {isLoadingSimulator && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: colors.loading,
            zIndex: 900,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "18px",
          }}
        >
          <Lottie
            style={{
              width: 350,
              height: 350,
              marginBottom: 15,
            }}
            loop
            autoPlay
            animationData={require("../../assets/animations/cargando.json")}
          />
        </div>
      )}

      {/* Deslizador de monto */}
      <Box my={6}>
        <Typography sx={{ fontSize: 20, color: colors.gray }}>
          {t("simulator.slide")}
        </Typography>
        <CustomSlider
          value={parseInt(data.c200_valor)}
          min={valSlider.min}
          step={valSlider.frecuency}
          max={valSlider.max}
          onChange={(
            event: React.SyntheticEvent | Event,
            value: number | number[]
          ) => setData({ ...data, c200_valor: value + "" })}
          onChangeCommitted={(
            event: React.SyntheticEvent | Event,
            value: number | number[]
          ) => handleChangeDueValue(value)}
        />
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          sx={{
            fontSize: 20,
            color: colors.gray,
            lineHeight: "1",
            fontWeight: "bold",
            mb: 0.5,
          }}
        >
          Valor préstamo
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 28, sm: 30, md: 33 },
            fontWeight: "bold",
            color: colors.primary,
            lineHeight: "1",
          }}
        >
          ${formatNumberPoint(data.c200_valor)}
        </Typography>

        {/* METODO DE PAGO */}
        <Box sx={{ width: { xs: "80%", sm: "350px" }, alignItems: "center" }}>
          <Box sx={{ textAlign: "left", width: "100%" }}>
            <Typography
              sx={{
                fontSize: 18,
                color: colors.darkSlateGray,
                marginTop: 5,
              }}
            >
              Periodicidad
            </Typography>
          </Box>

          <FormControl sx={{ width: { xs: "100%", sm: "350px" } }}>
            <Select
              labelId="demo-simple-select-label"
              value={data.c200_rowid_periodo}
              onChange={({ target }) =>
                handleChangePeriodicity(
                  target.value,
                  target.value === "1" ? "4" : "2"
                )
              }
              sx={{
                color: colors.black,
                width: "100%",
                textAlign: "center",
                borderRadius: 2,
                fontSize: { xs: "13px", md: "16px" },
              }}
            >
              {/* <MenuItem value="1">{t("simulator.paymentMethod1")}</MenuItem> */}
              <MenuItem value="4">{t("simulator.paymentMethod2")}</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {/* CANTIDAD DE CUOTAS */}
        <Box sx={{ width: { xs: "80%", sm: "350px" }, alignItems: "center" }}>
          <Box sx={{ textAlign: "left", width: "100%" }}>
            <Typography
              sx={{
                fontSize: 18,
                color: colors.darkSlateGray,
                marginTop: 1.5,
              }}
            >
              Plazo
            </Typography>
          </Box>

          <FormControl sx={{ width: { xs: "100%", sm: "350px" } }}>
            <Select
              labelId="demo-simple-select-label"
              value={data.c200_plazo}
              onChange={({ target }) =>
                handleChangeCantDues(target.value as string)
              }
              sx={{
                color: colors.black,
                width: "100%",
                textAlign: "center",
                borderRadius: 2,
                fontSize: { xs: "13px", md: "16px" },
              }}
            >
              {arrPlazo.map((item) => {
                return <MenuItem value={item.id}>{item.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </Box>

        {/* VALOR A PAGAR POR CUOTA */}
        <Box sx={{ mt: 5, mb: 8 }}>
          <Typography
            sx={{
              fontSize: { xs: 28, sm: 30, md: 35 },
              fontWeight: "bold",
              color: colors.primary,
              lineHeight: "1",
            }}
          >
            {dueValue !== "0" ? (
              `$${formatNumberPoint(dueValue)}`
            ) : (
              <CircularProgress color="success" />
            )}
          </Typography>
          <Typography
            sx={{
              fontSize: 20,
              color: colors.gray,
              lineHeight: "1",
              fontWeight: "bold",
            }}
          >
            Valor de cuota
          </Typography>
        </Box>

        <ColorButton
          sx={{
            padding: "15px 40px !important",
            fontSize: { xs: "13px", md: "16px" },
            textTransform: "uppercase",
            marginBottom: { xs: 2, md: 0 },
            borderRadius: "11px !important",
            width: { xs: "80%", sm: 350 },
          }}
          onClick={() => handlePreSubmitSimulation()}
        >
          {t("simulator.request")}
        </ColorButton>
      </Box>
    </Box>
  );
}
