import React from "react";
import { AuthContext } from "../hooks/useAuth.tsx";
import { errorAlert, MySwal, Modal } from "../common/alerts.tsx";
import { Box, FormHelperText, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { colors, shadow } from "../styles/theme.tsx";
import {
  JSONToForm,
  requestSquematic,
  useUpdatePasoRenovacion,
} from "../common/CommonFunctions.tsx";
import { ColorButton } from "./Button.tsx";
import { ModalPreview } from "./Modals.tsx";
import { getValueStorage, saveValueStorage } from "../common/storage.ts";
import LocationPin from "./LocationPin.tsx";
import { useFormik } from "formik";
import { yupAddressValidations } from "../common/validations/yupAddressValidations.ts";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { SVG_pointer } from "../assets/svg/MapIcons.ts";
import { envProps } from "../common/envProps.ts";
import InputField from "./InputField.tsx";
import {
  IRequestDocuments_Request,
  navigationModals,
} from "../constants/types.tsx";
import {
  IRegisterStep,
  IRegisterStepState,
} from "../pages/User/RegisterComponent.tsx";
import { handleCancelVc } from "../pages/User/RegisterSubComponents/NoResponse.tsx";
import { useNavigate } from "react-router-dom";

type UploadFileProps = IRegisterStepState & navigationModals;

export default function UploadFile({
  setIsLoading,
  setRegisterStep,
}: UploadFileProps) {
  const { parseRenovacion } = useUpdatePasoRenovacion();
  const { t } = useTranslation();
  const { getUser, signIn } = React.useContext(AuthContext);
  const [docs, setDocs] = React.useState<any[]>([]);
  const registerStep: IRegisterStep = getValueStorage("registerStep");
  const [openPreview, setOpenPreview] = React.useState(false);
  const [selectedType, setSelectedType] =
    React.useState<keyof typeof state>("laboral");
  const [viewMap, setViewMap] = React.useState(false);
  const [formErrors, setFormErrors] = React.useState<{
    [key: string]: boolean | null;
  }>({
    laboral: null,
    bancario: null,
    comercio: null,
  });
  const [state, setState] = React.useState<{
    laboral: string | null;
    comercio: string | null;
    bancario: string | null;
  }>({
    laboral: null,
    comercio: null,
    bancario: null,
  });

  const navigate = useNavigate();

  // Consulta los datos json de los documentos solicitados
  const fetchDocuments = async () => {
    try {
      const response = await JSON.parse(getUser.revalidateLaborDocs.c110_json);

      // Filtrar los documentos que tengan id 125
      const filteredDocs = response.filter((doc: any) => doc.id === 125);

      setDocs(filteredDocs);
    } catch (error) {
      // convertir error en string para manejar tanto objetos Error como strings simples
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      errorAlert("Error", errorMessage, "");
    }
  };

  React.useEffect(() => {
    saveValueStorage("authSession", {
      ...getValueStorage("authSession"),
      getLocation: {
        latitude: undefined,
        longitude: undefined,
      },
    });
    fetchDocuments();
  }, []);

  // Inicializar variables
  const formik = useFormik({
    initialValues: {
      home_address: "",
      home_address_description: "",
      hasVideoCalll:
        getValueStorage("authSession").hasVideoCall.c155_rowid_estado + "" ===
        "4",
    },
    validationSchema: yupAddressValidations,
    onSubmit: (values) => {
      const updatedErrors = { ...formErrors };
      // Buscamos los documentos requeridos en el json y los marcamos como true en los errores
      docs.forEach((doc) => {
        if (doc.check) {
          const key = doc.name.toLowerCase().split(" ").pop();
          // Los documentos ya subidos no se cambian de estado
          if (updatedErrors[key] !== false) {
            updatedErrors[key] = true;
          }
        }
      });
      // Segun el documento requerido, se envía el error al formulario
      setFormErrors(updatedErrors);

      if (
        getValueStorage("authSession").hasVideoCall.c155_rowid_estado + "" ===
          "4" &&
        !getValueStorage("authSession").getLocation?.latitude &&
        !getValueStorage("authSession").getLocation?.longitude
      ) {
        errorAlert(
          "Error",
          "No se pudo tomar la ubicación actual, por favor intente nuevamente.",
          ""
        );
      } else if (!Object.values(updatedErrors).includes(true)) {
        // Ya no hay documentos requeridos
        handleShedule(values);
      }
    },
  });

  // Envia todo lo del formulario
  const handleShedule = async (values: any) => {
    try {
      setIsLoading(true);
      let idVideoCall = "";
      const getUserTemp = getValueStorage("authSession");

      if (getUser?.hasVideoCallThird?.c155_rowid) {
        idVideoCall = getUserTemp.hasVideoCallThird.c155_rowid;
      } else {
        idVideoCall = getUserTemp.hasVideoCall.c155_rowid;
      }

      let direccionTrabajo = values.home_address;
      if (!direccionTrabajo) {
        direccionTrabajo = getUserTemp.hasFinancial.c126_direccion_trabajo + "";
      }

      const objToSend: IRequestDocuments_Request = {
        c155_rowid: idVideoCall,
        c200_rowid:
          getUserTemp.inProcessRenovation?.inProcessRenovation ||
          getUserTemp.isOld
            ? getUserTemp.loanRequest!.c200_rowid
              ? getUserTemp.loanRequest!.c200_rowid
              : ""
            : getUserTemp.hasVideoCall.c155_rowid_prestamo,
        c110_rowid: getUserTemp.revalidateLaborDocs.c110_rowid ?? "",
        c126_lat_company: getValueStorage("authSession").getLocation?.latitude
          ? getValueStorage("authSession").getLocation?.latitude.toString()
          : "",
        c126_lon_company: getValueStorage("authSession").getLocation?.longitude
          ? getValueStorage("authSession").getLocation?.longitude.toString()
          : "",
        c155_rowid_estado: getUserTemp.revalidateLaborVideo ? "4" : "6",
        c126_direccion_trabajo: direccionTrabajo,
        c126_desc_dir_company:
          values?.home_address_description?.toString() ??
          getUserTemp.hasFinancial.c126_desc_dir_company + "",
      };

      // Detecta que state esta activo y adiciona el assets respectivo
      if (state.bancario) {
        objToSend.c125_url_extracto_bancario = state.bancario;
      }
      if (state.comercio) {
        objToSend.c125_url_camara_comercio = state.comercio;
      }
      if (state.laboral) {
        objToSend.c125_url_carta_laboral = state.laboral;
      }

      const formData = JSONToForm(objToSend);

      const res = await requestSquematic(
        "POST",
        "/api/app/contact_details/save_laboral_documents",
        formData,
        getUserTemp.token
      );

      if (res) {
        // Si esta en proceso de renovacion o si es un usuario antiguo, y no tiene solicitud de video de laboral entonces cambia los pasos
        if (
          !getUserTemp.revalidateLaborVideo?.c110_json &&
          (getUserTemp.inProcessRenovation?.inProcessRenovation ||
            getUserTemp.isOld)
        ) {
          console.log("uploadFile if 1");
          let objSession = {
            ...getUserTemp,
            revalidateLaborDocs: {
              c110_fecha_actualizacion: "",
              c110_fecha_creacion: "",
              c110_ind_estado: "",
              c110_json: "",
              c110_notas: "",
              c110_rowid: "",
              c110_rowid_usuario: "",
              c110_usuario_creacion: "",
            },
            getLocation: {
              latitude: null,
              longitude: null,
            },
          };
          signIn(objSession);
          saveValueStorage("authSession", objSession);
          if (parseRenovacion().tipo_videollamada !== "0") {
            console.group("debe ir a requestPreAproved");
            // await updatePasoRenovacion("5", setIsLoading);
            //navigation.navigate("RequestPreAproved");
          } else if (
            getUserTemp.hasVideoCallThird?.c155_rowid_estado + "" === "6" ||
            getUserTemp.hasVideoCall.c155_rowid_estado
          ) {
            console.log("uploadFile else if parseRenovacion tipovideollamada");
            /// Si se le solicita documentos laborales desde el panel cuando valida la informacion laboral siendo renovacion
            //  Al hacer login y enviar los documentos, se dirige al home con getUser.hasVideoCallThird.c155_rowid_estado === ""
            let tempThirdVc =
              getUserTemp.hasVideoCallThird ?? getUserTemp.hasVideoCall;
            if (
              tempThirdVc?.c155_rowid_estado === "6" ||
              tempThirdVc?.c155_rowid_estado === "4"
            ) {
              tempThirdVc.c155_rowid_estado = "";
            }
            // await updatePasoRenovacion("0", setIsLoading, "0", "0");
            let objSession = {
              ...getUserTemp,
              loanRequest: {
                ...getUserTemp.loanRequest,
                c204_paso_renovacion: JSON.stringify({
                  paso: "0",
                  tipo_videollamada: "0",
                  estado_laboral: "0",
                }),
              },
              revalidateLaborDocs: {
                c110_fecha_actualizacion: "",
                c110_fecha_creacion: "",
                c110_ind_estado: "",
                c110_json: "",
                c110_notas: "",
                c110_rowid: "",
                c110_rowid_usuario: "",
                c110_usuario_creacion: "",
              },
              hasVideoCallThird: tempThirdVc,
              hasVideoCall: {
                ...getUserTemp.hasVideoCall,
                c155_rowid_estado: "",
              },
              getLocation: {
                latitude: null,
                longitude: null,
              },
              dashboard: false,
            };

            if (getUserTemp.loanActive?.c200_rowid) {
              objSession.dashboard = true;
              signIn(objSession);
              saveValueStorage("authSession", objSession);
              navigate("/");
            } else {
              signIn(objSession);
              saveValueStorage("authSession", objSession);
            }
          } else {
            console.log("uploadFile else if parseRenovacion");
            // await updatePasoRenovacion("0", setIsLoading, "0", "0");
            //navigation.popToTop();
          }
        } else {
          console.log("uploadFile else 1", getUserTemp);
          let objSession = {
            ...getUserTemp,
            revalidateLaborDocs: {
              c110_fecha_actualizacion: "",
              c110_fecha_creacion: "",
              c110_ind_estado: "",
              c110_json: "",
              c110_notas: "",
              c110_rowid: "",
              c110_rowid_usuario: "",
              c110_usuario_creacion: "",
            },
            hasVideoCall: {
              ...getUserTemp.hasVideoCall,
              c155_rowid_estado: getUserTemp.revalidateLaborVideo ? "4" : "6",
            },
            getLocation: {
              latitude: null,
              longitude: null,
            },
          };
          signIn(objSession);
          saveValueStorage("authSession", objSession);
          if (getUserTemp.revalidateLaborVideo.c110_json) {
            console.log("else if revalidateLaborVideo");
            // ir a revalidacion de video
            let obj = {
              prevStep: registerStep.nextStep,
              nextStep: 28,
              desc: "El usuario ha subido los documentos y se solicita video del negocio",
            };
            saveValueStorage("registerStep", obj);
            if (setRegisterStep) setRegisterStep(obj);
          } else if (getUserTemp.hasValidations) {
            console.log("else if hasValidations");
            // ir a revalidacion de datos
            let obj = {
              prevStep: registerStep.nextStep,
              nextStep: 29,
              desc: "El usuario ha subido los documentos y se solicita validacion de datos",
            };
            saveValueStorage("registerStep", obj);
            if (setRegisterStep) setRegisterStep(obj);
          } else {
            console.log("else if else");
            //Ir a espera
            let obj = {
              prevStep: registerStep.nextStep,
              nextStep: 24,
              desc: "El usuario ha subido los documentos",
            };
            saveValueStorage("registerStep", obj);
            if (setRegisterStep) setRegisterStep(obj);
          }
        }
      }
    } catch (error) {
      // convertir error en string para manejar tanto objetos Error como strings simples
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      errorAlert("Error UploadFile: ", errorMessage, "");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e, nameLower, type) => {
    const file = e.target.files[0];

    if (file) {
      const validType = file.type === "application/pdf";

      if (!validType) {
        errorAlert("Error", t("uploadFile.onlyPdf"), "");
        e.target.value = ""; // Resetea el input si el archivo no es válido
        return;
      } else if (file.size > 210000) {
        // 210000 bytes = 210 KB
        setIsLoading(false);
        errorAlert("Error", t("uploadFile.limit"), "");
        return;
      }

      // Llama a la función para manejar el archivo válido
      pickDocument(e, nameLower, type);
    }
  };

  // Subir documentos temporalmente
  const pickDocument = async (
    e: any,
    documentType: string,
    type: keyof typeof state
  ) => {
    try {
      setIsLoading(true);

      const file = e.target.files[0];
      if (file) {
        const replaceType = documentType.replace(/ /g, "_");

        const newFileName = `${getUser.token.split("|")[0]}_${
          getUser.loanRequest?.c200_rowid
        }_${replaceType}${file.name.substring(file.name.lastIndexOf("."))}`;

        // Crear un nuevo archivo con el nombre cambiado
        const renamedFile = new File([file], newFileName, {
          type: file.type,
        });

        const objToSend = {
          file: renamedFile,
          doc_type: replaceType,
        };

        const formData = JSONToForm(objToSend);

        const res = await requestSquematic(
          "POST",
          "/api/app/contact_details/save_file_url",
          formData,
          getUser.token
        );

        if (res && res.results) {
          // Eliminamos el error de los documentos requeridos, deacuerdo al tipo de documento subido
          setFormErrors({ ...formErrors, [type]: false });
          // Actualizamos el estado de los documentos deacuerdo al tipo de documento subido
          setState({
            ...state,
            [type]: res.results,
          });

          openDocument(type);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setState({
          laboral: state.laboral ?? null,
          comercio: state.comercio ?? null,
          bancario: state.bancario ?? null,
        });
      }
    } catch (error) {
      errorAlert("Oops!", t("uploadFile.couldNotUpload"), "");
      setIsLoading(false);
      setState({
        laboral: state.laboral ?? null,
        comercio: state.comercio ?? null,
        bancario: state.bancario ?? null,
      });
    }
  };

  // Abre el modal deacuerdo al tipo de archivo subido
  const openDocument = async (type: keyof typeof state) => {
    setSelectedType(type);
    setOpenPreview(true);
  };

  React.useEffect(() => {
    // Si hay apertura de preview, se muestra el modal
    if (openPreview) {
      Modal(
        <ModalPreview
          state={state[selectedType]} // Documento a mostrar, deacuerdo al tipo de documento selecconado
          onClose={() => MySwal.close()}
        />,
        {
          title: "",
          showCloseButton: true,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          willClose: () => {
            setOpenPreview(false); // Accion para el boton de cerrar el modal: es cerrar el preview
          },
        }
      );
    }
  }, [openPreview]);

  // Rederiza los documentos solicitados
  const renderDocument = (doc: any, index: number) => {
    let title = "";
    let updateText = "";
    let type: keyof typeof state = "laboral";
    const nameLower = doc.name.toLowerCase();

    if (nameLower === "carta laboral") {
      title = "workingLetter";
      updateText = "workingLetter";
      type = "laboral";
    } else if (nameLower === "extracto bancario") {
      title = "bankState";
      updateText = "yourBankState";
      type = "bancario";
    } else if (nameLower === "camara de comercio") {
      title = "chamberCommerce";
      updateText = "chamberCommerce";
      type = "comercio";
    }

    // Detecta que documento se subio y cambia a la imagen de documento cargado
    const isDocumentUploaded = (name: string) => {
      if (name === "carta laboral") return state.laboral !== null;
      if (name === "extracto bancario") return state.bancario !== null;
      if (name === "camara de comercio") return state.comercio !== null;
      return false;
    };

    return (
      // Los elementos en la lista tienen claves duplicadas (125) a razon de ello se utiliza index
      <Box key={`${doc.id}-${index}`} mt={8}>
        <Box>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: 17,
              color: colors.primary,
              textTransform: "uppercase",
              marginBottom: "5px",
              textAlign: "center",
            }}
            lineHeight={1}
          >
            {t(`uploadFile.${title}`)}
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              color: colors.black,
              lineHeight: 1.3,
              marginBottom: "20px",
              textAlign: "justify",
              mt: 2,
            }}
          >
            {t("uploadFile.reviewedAccuracy")}{" "}
            <strong>{t("uploadFile.subReviewedAccuracy")}</strong>{" "}
            {t("uploadFile.afterReviewedAccuracy")}{" "}
            <strong>{t("uploadFile.endReviewedAccuracy")}</strong>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box
            component="label"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 3,
              cursor: "pointer",
            }}
          >
            <input
              type="file"
              accept="application/pdf"
              style={{ display: "none" }}
              disabled={getUser.isLoading}
              onChange={(e) => handleFileChange(e, nameLower, type)}
            />

            {!isDocumentUploaded(nameLower) &&
              (formErrors[type] ? (
                // Documento requerido
                <img
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                  }}
                  src={require("../assets/image/ErrorDocumento.webp")}
                  alt=""
                />
              ) : (
                <img
                  style={{
                    width: 100,
                    height: 100,
                    objectFit: "contain",
                  }}
                  src={require("../assets/image/Documento.webp")}
                  alt=""
                />
              ))}
            {isDocumentUploaded(nameLower) && (
              //  Documento cargado
              <>
                <img
                  style={{ width: 100, height: 100, objectFit: "contain" }}
                  src={require("../assets/image/Chulo.webp")}
                  alt=""
                />
                <Typography
                  sx={{ color: colors.slateGray, mt: 1, textAlign: "center" }}
                  lineHeight={1.2}
                >
                  {t("uploadFile.documentUpload")}
                </Typography>
              </>
            )}

            <Typography
              sx={{ color: colors.slateGray, textAlign: "center" }}
              marginTop={isDocumentUploaded(nameLower) ? 1 : 1.5}
              lineHeight={1.2}
            >
              {t("uploadFile.pressHere")}
              <strong>{t("uploadFile.subPressHere")}</strong>
              {!isDocumentUploaded(nameLower) && (
                <> {t("uploadFile.afterPressHere")}</>
              )}
              {isDocumentUploaded(nameLower) && (
                <>{t("uploadFile.documentReplace")}</>
              )}
            </Typography>
            <Typography
              lineHeight={1.2}
              sx={{
                color: colors.slateGray,
                textTransform: "lowercase",
                textAlign: "center",
              }}
            >
              {t(`uploadFile.${updateText}`)}
            </Typography>
          </Box>

          {/* Formulario de error de documento requerido */}
          {formErrors[type] && !isDocumentUploaded(nameLower) && (
            <FormHelperText
              style={{
                color: colors.red,
                fontSize: 11,
                width: "100%",
                textAlign: "center",
                lineHeight: 1.1,
                marginTop: 10,
                whiteSpace: "wrap",
              }}
            >
              * {t("yup.documentRequired")}
            </FormHelperText>
          )}

          {isDocumentUploaded(nameLower) && (
            <>
              <ColorButton
                sx={{
                  width: "fit-content",
                  padding: "10px 20px",
                  fontSize: 13,
                  marginTop: 4,
                  backgroundColor: colors.primary,
                  color: colors.white,
                  textTransform: "uppercase",
                  borderRadius: `10px !important`,
                  lineHeight: 1.2,
                }}
                onClick={() => openDocument(type)}
              >
                {t("uploadFile.viewDoc")}
              </ColorButton>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: envProps.GOOGLE_KEY,
    libraries: ["geometry"],
  });

  const google = window.google;

  if (!isLoaded) {
    return null;
  }

  return (
    <>
      {viewMap ? (
        <LocationPin
          inputAddress={formik.values.home_address}
          setViewMap={setViewMap}
          title={t("contactInformation.mapLabel")}
        />
      ) : (
        <Box>
          {getUser.hasVideoCall.c155_rowid_estado + "" === "4" && (
            <Box>
              <InputField
                name="home_address"
                label={t("financialInformation.companyDir")}
                formik={formik}
                maxLength={150}
                sx={{ mb: 2 }}
                disabled={getUser.isLoading}
                onChange={() =>
                  signIn({
                    ...getUser,
                    getLocation: {
                      latitude: undefined,
                      longitude: undefined,
                    },
                  })
                }
                onBlur={() => {
                  // Simula el touch del campo para mostrar los errores
                  formik.setTouched({ home_address: true });

                  //Validar otros campos (como 'prueba' o 'admin')
                  if (
                    formik.values.home_address !== "" &&
                    !formik.values.home_address
                      .split(" ")
                      .some(
                        (word) =>
                          word.toLowerCase() === "prueba" ||
                          word.toLowerCase() === "admin"
                      ) &&
                    !formik.errors.home_address
                  ) {
                    setViewMap(true);
                  }
                }}
              />

              <InputField
                name="home_address_description"
                label={t("contactInformation.dirDesc")}
                formik={formik}
                maxLength={150}
                disabled={getUser.isLoading}
                type="textarea"
              />
              {getValueStorage("authSession").getLocation?.latitude &&
                getValueStorage("authSession").getLocation?.longitude && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: { xs: "100%", xl: 410 },
                      margin: "0 auto",
                    }}
                  >
                    <GoogleMap
                      mapContainerStyle={{
                        width: "100%",
                        height: 150,
                        borderRadius: 5,
                        marginTop: 20,
                        boxShadow: shadow.black,
                      }}
                      center={{
                        lat: getValueStorage("authSession").getLocation
                          ?.latitude,
                        lng: getValueStorage("authSession").getLocation
                          ?.longitude,
                      }}
                      onLoad={(map) => {
                        map.setCenter({
                          lat:
                            getValueStorage("authSession").getLocation
                              ?.latitude ?? 0,
                          lng:
                            getValueStorage("authSession").getLocation
                              ?.longitude ?? 0,
                        });
                        map.setZoom(17);
                      }}
                      options={{
                        disableDefaultUI: true, // Desactiva la UI predeterminada del mapa
                        draggable: false, // Desactiva el arrastre del mapa
                        scrollwheel: false, // Desactiva el desplazamiento con la rueda del mouse
                        zoomControl: false, // Desactiva el control de zoom
                        streetViewControl: false, // Desactiva el control de vista de calle
                        mapTypeControl: false, // Desactiva el control de tipo de mapa
                        clickableIcons: false, // Desactiva los iconos de clic
                        headingInteractionEnabled: false, // Desactiva la interacción con la cabecera
                        keyboardShortcuts: false, // Desactiva los atajos de teclado
                      }}
                    >
                      <Marker
                        position={{
                          lat: getValueStorage("authSession").getLocation
                            ?.latitude,
                          lng: getValueStorage("authSession").getLocation
                            ?.longitude,
                        }}
                        //   animation={google.maps.Animation.DROP}
                        icon={{
                          path: SVG_pointer,
                          scale: 0.075,
                          strokeWeight: 0.8,
                          fillColor: colors.primary,
                          fillOpacity: 1,
                          anchor: new google.maps.Point(200, 520),
                        }}
                      />
                    </GoogleMap>

                    <ColorButton
                      sx={{
                        width: "fit-content",
                        padding: "0px 20px",
                        fontSize: 12,
                        mt: 2,
                        height: 50,
                      }}
                      onClick={() => setViewMap(true)}
                      disabled={getUser.isLoading}
                    >
                      {t("contactInformation.changeUbi")}
                    </ColorButton>
                  </Box>
                )}
            </Box>
          )}
          <Box>{docs.map(renderDocument)}</Box>
          {docs.length > 0 && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              marginTop={8}
            >
              <ColorButton
                sx={{
                  width: "fit-content",
                  padding: { xs: "15px 60px", sm: "15px 80px" },
                  fontSize: 16,
                }}
                disabled={getUser.isLoading}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {t("common.continue")}
              </ColorButton>
              <ColorButton
                sx={{
                  width: "fit-content",
                  padding: "8px 20px",
                  fontSize: 13,
                  mt: 1,
                  background: colors.blueGray,
                  borderColor: colors.blueGray,
                  borderRadius: "13px !important",
                }}
                disabled={getUser.isLoading}
                onClick={() =>
                  handleCancelVc({
                    prevStep: registerStep && registerStep.nextStep,
                    desc: "Cancelar solicitud desde uploadfile",
                    setRegisterStep,
                    setIsLoading,
                  })
                }
              >
                {t("common.cancelRequest")}
              </ColorButton>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
