import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomeDashboard from "./HomeDashboard.tsx";
import Profile from "./pages/User/Profile.tsx";
import HeaderDashboard from "./components/HeaderDashboard.tsx";
import SidebarDashboards from "./components/SidebarDashboard.tsx";
import { DarkModeProvider } from "./hooks/DarkModeProvider.js";
import { getValueStorage } from "../../common/storage.ts";
import NotFound from "./NotFound.tsx";

export default function DashboardRoutes() {
  const location = useLocation();
  const [open, setOpen] = React.useState(true);

  // Estado para guardar las funciones que se crean en el header, para que se puedan llamar desde el home
  const [headertohome, setHeadertohome] = React.useState<{
    [key: string]: (() => void) | null;
  }>({});

  // Función para agregar una función al estado
  const addFunctionToHeadertohome = (key: string, newFunction: () => void) => {
    setHeadertohome((prev) => ({ ...prev, [key]: newFunction }));
  };

  // Función para eliminar una función específica -- No borrar
  // const removeHeadertohome = (key: string) => {
  //   setHeadertohome((prev) => {
  //     const updated = { ...prev };
  //     delete updated[key];
  //     return updated;
  //   });
  // };

  // Función para declarar null una función específica
  const removeHeadertohome = (key: string) => {
    setHeadertohome((prev) => ({
      ...prev,
      [key]: null, // En lugar de eliminar, establece el valor en null y si una funcion es null, se valida para posibles disabled
    }));
  };

  React.useEffect(() => {
    let dashboardConfig = getValueStorage("dashboardConfig");
    if (dashboardConfig) {
      document.body.classList.toggle("dark", dashboardConfig.isDarkMode);
    }
  }, []);

  React.useEffect(() => {
    // Función que se ejecutará en cada cambio de tamaño
    function handleResize() {
      if (window.innerWidth >= 1200) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    }

    // Agrega el listener de "resize"
    window.addEventListener("resize", handleResize);

    // Limpia el listener cuando el componente se desmonta
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //Cuando navegue cierre el sidebar
  React.useEffect(() => {
    if (window.innerWidth >= 1200) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location]);

  return (
    <DarkModeProvider>
      <div className="flex h-full w-full">
        <SidebarDashboards open={open} onClose={() => setOpen(false)} />
        <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
          <main
            className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[313px]`}
          >
            <div className="h-full">
              <HeaderDashboard
                onOpenSidenav={() => setOpen(true)}
                headertohome={addFunctionToHeadertohome}
                removeFunction={removeHeadertohome}
              />
              {/* Routes */}
              <div className="pt-5 mx-auto mb-auto h-full min-h-[100vh] p-2 md:pr-2 ">
                <Routes>
                  <Route
                    path="/"
                    element={
                      <HomeDashboard
                        headertohome={headertohome}
                        removeFunction={removeHeadertohome}
                      />
                    }
                  />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </div>
          </main>
        </div>
      </div>
    </DarkModeProvider>
  );
}
