import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home.tsx";
import Footer from "./components/Footer.tsx";
import OrganizationalCulture from "./pages/AboutUs/OrganizationalCulture.tsx";
import WhoAreWe from "./pages/AboutUs/WhoAreWe.tsx";
import RegisterComponent from "./pages/User/RegisterComponent.tsx";
import ContactUs from "./pages/AboutUs/ContactUs.tsx";
import Login from "./pages/User/Login.tsx";
import LinkStore from "./pages/OurServices/LinkStore.tsx";
import FrequentsQuestions from "./pages/AboutUs/FrequentsQuestions.tsx";
import i18n from "./constants/translations/i18n.js";
import Header from "./components/Header.tsx";
import PrivacyPolicies from "./pages/User/PrivacyPolicies.tsx";
import TermsConditions from "./pages/User/TermsConditions.tsx";
import DeleteAccount from "./pages/User/DeleteAccount.tsx";


const AppRoutes = () => {
  const location = useLocation();
  const noFooterRoutes = [
    "/sign-in",
    "/sign-up",
  ];

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-services" element={<LinkStore />} />
        <Route
          path="/culture-organization"
          element={<OrganizationalCulture />}
        />
        <Route path="/who-we-are" element={<WhoAreWe />} />
        <Route path="/customer-service" element={<ContactUs />} />
        <Route path="/sign-in" element={<Login />} />
        <Route path="/sign-up" element={<RegisterComponent />} />
        <Route path="/frequently-questions" element={<FrequentsQuestions />} />
        <Route path="/privacy-policies" element={<PrivacyPolicies />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {/* Renderiza el Footer solo si la ruta no está en el arreglo noFooterRoutes */}
      {!noFooterRoutes.includes(location.pathname) && <Footer />}
    </>
  );
};

export default AppRoutes;
