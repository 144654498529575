import React from "react";
import { Box, Typography } from "@mui/material";
import { colors, sizes, shadow } from "../../styles/theme.tsx";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import { ColorButton } from "../../components/Button.tsx";
import { CssTextField } from "../../components/CssTextField.tsx";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../hooks/useAuth.tsx";
import { getValueStorage, wipeValueStorage } from "../../common/storage.ts";
import { Link, useNavigate } from "react-router-dom";
import { IUserAuth } from "../../constants/types.tsx";
import {
  RemoveRedEyeIcon,
  VisibilityOffIcon,
} from "../Dashboard/assets/iconsMui.tsx";
import { requestSquematic } from "../../common/CommonFunctions.tsx";
import i18n from "../../constants/translations/i18n.js";
import { errorAlert, successAlert } from "../../common/alerts.tsx";

export default function DeleteAccount() {
  const navigate = useNavigate();
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const { setIsLoading, getUser } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const [password, setPassword] = React.useState<string>("");
  const [visiblePassword, setVisiblePassword] = React.useState(false);
  const [step, setStep] = React.useState<number>(0);
  const [deleteAccount, setDeleteAccount] = React.useState(false);
  const [email, setEmail] = React.useState<{ value: string; isValid: boolean }>(
    { value: "", isValid: false }
  );

  //Validamos si estamos logeados
  React.useEffect(() => {
    const authSession: IUserAuth = getValueStorage("authSession");
    if (authSession && authSession.login) {
      wipeValueStorage("authSession");
      navigate("/");
    }
  }, []);

  const handleChange = (value: string) => {
    setEmail({
      value,
      isValid: value !== "" && re.test(String(value).toLowerCase()),
    });
  };

  const handleSigIn = async () => {
    setIsLoading(true);
    const res = await requestSquematic(
      "POST",
      "/api/app/user/login",
      {
        c001_clave: password,
        c001_correo_electronico: email.value,
        c001_token_dispositivo: null,
        idioma: i18n.language,
      }
    );

    if (res && res.results) {
      // Validar nuevamente la contraseña
      if (step === 2) {
        handleDelete(res.results.token);
      } else {
        setStep(1);
        setPassword(""); // Limpiar la contraseña
        if (res.results.user.loan_active?.c200_rowid) {
          setDeleteAccount(false);
        } else {
          setDeleteAccount(true);
        }
      }
    }

    setIsLoading(false);
  };

  // Eliminar cuenta
  const handleDelete = async (token: string) => {
    setIsLoading(true);
    try {
      const res = await requestSquematic(
        "POST",
        "/api/app/user/delete_account",
        {},
        token
      );

      if (res && res.results) {
        await successAlert("Cuenta eliminada con exito");
        navigate("/");
      }
    } catch (error) {
      // convertir error en string para manejar tanto objetos Error como strings simples
      const errorMessage = error instanceof Error ? error.message : String(error);
      errorAlert("Error", errorMessage, "");
    } finally {
      setIsLoading(false);
    }
  }

  //Cambiar la visibilidad del campo de contraseña
  const handleClickShowPassword = (visibility: boolean, idElement: string) => {
    if (idElement === "new_password") {
      setVisiblePassword(visibility);
    }
    let element = document.getElementById(idElement) as HTMLInputElement;
    if (element) {
      element.type = visibility ? "text" : "password";
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
      }}
    >
      <Box
        sx={{
          flex: "1",
          flexGrow: 1,
          marginTop: `${sizes.header}px`,
          paddingTop: "60px",
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            flexGrow: 1,
            width: { xs: "95%", md: 865, lg: 850, xl: 960 },
            margin: "0 auto",
          }}
        >
          {/* Titulo */}
          <Box
            sx={{
              padding: { xs: "50px 50px 50px 35px", sm: "50px" },
              position: "relative",
              display: "flex",
              width: { md: "90%" },
              margin: "0 auto",
            }}
          >
            <div
              style={{
                width: 10,
                height: 120,
                background: colors.primary,
                position: "absolute",
                top: "50%",
                left: 0,
                borderRadius: 2,
                transform: "translateY(-50%)",
              }}
            />
            <Box>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 28, sm: 32, md: 43 },
                  color: colors.primary,
                  textTransform: "uppercase",
                }}
                lineHeight={1}
              >
                Eliminar cuenta
              </Typography>

              <Typography
                sx={{
                  fontSize: { xs: 16, md: 18 },
                  color: colors.gray,
                  marginTop: "10px",
                  lineHeight: 1.3,
                }}
              >
                Solo puedes eliminar tu cuenta si tu crédito ha sido pagado en su totalidad.
              </Typography>
            </Box>
          </Box>

          {/* Formulario */}
          <Box mt={5} width={"100%"}>
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              sx={{ flexGrow: 1 }}
            >
              <Box
                sx={{
                  background: colors.white,
                  height: "auto",
                  width: { xs: "95%", sm: 500, xl: 600 },
                  borderRadius: "18px",
                  boxShadow: shadow.black,
                  padding: {
                    xs: "40px 30px 80px 30px",
                    sm: "40px 70px 80px 70px",
                  },
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 10,
                }}
                mt={10}
              >
                <Box
                  sx={{
                    width: { xs: 180, lg: 235 },
                    height: { xs: 180, lg: 235 },
                    margin: "0 auto",
                    marginTop: { xs: "-117px", sm: "-120px" },
                  }}
                >
                  <img
                    src={require("../../assets/image/user.webp")}
                    alt={""}
                    loading="lazy"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
                {/* Login */}
                {step === 0 && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    mt={6}
                  >
                    <p className="mb-2 text-md text-secondary-900 text-left" style={{ lineHeight: 1.3 }}>
                      Ingresa tu correo electrónico y contraseña para continuar.
                    </p>
                    <CssTextField
                      placeholder={"Correo electronico"}
                      id="custom-css-outlined-input"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", xl: "70%" }, marginTop: 1 }}
                      onChange={({ target }) => handleChange(target.value)}
                      isError={!email.isValid && email.value.length !== 0}
                      onKeyUp={(e) => {
                        if (
                          e.key === "Enter" &&
                          email.value &&
                          email.isValid &&
                          password.length >= 4
                        ) {
                          handleSigIn();
                        }
                      }}
                    />
                    <CssTextField
                      placeholder="Contraseña"
                      id="new_password"
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {visiblePassword ? (
                              <RemoveRedEyeIcon
                                onClick={() =>
                                  handleClickShowPassword(false, "new_password")
                                }
                                className="cursor-pointer"
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() =>
                                  handleClickShowPassword(true, "new_password")
                                }
                                className="cursor-pointer"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", xl: "70%" } }}
                      onChange={({ target }) => {
                        // Filtrar solo números
                        const filteredValue = target.value.replace(/[^0-9]/g, "");
                        target.value = filteredValue;
                        setPassword(filteredValue);
                      }}
                      onKeyUp={(e) => {
                        if (
                          e.key === "Enter" &&
                          email.value &&
                          email.isValid &&
                          password.length >= 4
                        ) {
                          handleSigIn();
                        }
                      }}
                      inputProps={{
                        maxLength: 4, // Establece el máximo de caracteres aquí
                      }}
                    />
                    <ColorButton
                      variant="contained"
                      sx={{ fontSize: 20, width: { xs: "100%", sm: 245 } }}
                      disabled={
                        !email.value ||
                        !email.isValid ||
                        password.length < 4 ||
                        getUser.isLoading
                      }
                      onClick={handleSigIn}
                    >
                      {t("common.getInto")}
                    </ColorButton>
                  </Box>
                )}
                {/* Puede eliminar la cuenta? */}
                {step === 1 && (
                  <div className="flex mt-6 flex-col">
                    {deleteAccount ? (
                      <>
                        <p className="mb-2 text-lg text-secondary-900 text-center font-bold">
                          ¿Estás seguro de que deseas eliminar tu cuenta?
                        </p>
                        <p className="mb-2 text-md text-secondary-900 text-center md:text-left">
                          Esta acción es irreversible y se perderán todos tus datos asociados, incluyendo tu historial de créditos y movimientos.<br /><br /> Si estás completamente seguro, confirma tu decisión.
                        </p>
                        <div className="flex flex-row justify-center mt-5 gap-5">
                          <ColorButton
                            sx={{
                              width: "fit-content",
                              padding: { xs: "10px 20px", md: "12px 30px" },
                              fontSize: { xs: 15, lg: 16 },
                              marginTop: "20px",
                              borderRadius: "11px",
                              margin: 0
                            }}
                            onClick={() => setStep(2)}
                          >
                            Si
                          </ColorButton>
                          <ColorButton
                            sx={{
                              width: "fit-content",
                              padding: { xs: "10px 20px", md: "12px 30px" },
                              fontSize: { xs: 15, lg: 16 },
                              marginTop: "20px",
                              borderRadius: "11px",
                              margin: 0,
                              backgroundColor: colors.red,
                            }}
                            hoverColor={colors.red}
                            hoverBackground={colors.white}
                            onClick={() => navigate("/")}
                          >
                            No
                          </ColorButton>
                        </div>
                      </>

                    ) : (
                      <>
                        <p className="mb-2 text-md text-secondary-900 text-left">
                          Lo sentimos, pero <strong>no puedes eliminar tu cuenta </strong>en este momento. Tienes un crédito activo que aún no ha sido pagado en su totalidad.<br /><br /> Para proceder con la eliminación de tu cuenta, es necesario que liquides completamente tu deuda.<br /><br /> Si necesitas más información,
                          <Link
                            to="/customer-service"
                            style={{ fontWeight: "bold", textDecoration: "none", color: "inherit" }}
                          >
                            &nbsp;contáctanos.
                          </Link>
                        </p>
                        <div className="flex  justify-center ">
                          <ColorButton
                            sx={{
                              width: "fit-content",
                              padding: { xs: "10px 20px", md: "12px 30px" },
                              fontSize: { xs: 15, lg: 16 },
                              marginTop: "20px",
                              borderRadius: "11px",

                            }}

                            onClick={() => navigate("/")}
                          >
                            Volver
                          </ColorButton>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {/* Ingresar nuevamente la contraseña */}
                {step === 2 && (
                  <div className="flex mt-6 flex-col" style={{alignItems: "center"}}>
                    <p className="mb-2 text-md text-secondary-900 text-left">
                      Ingresa nuevamente tu contraseña para confirmar la eliminación de tu cuenta.
                    </p>
                    <CssTextField
                      placeholder="Contraseña"
                      id="new_password"
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            {visiblePassword ? (
                              <RemoveRedEyeIcon
                                onClick={() =>
                                  handleClickShowPassword(false, "new_password")
                                }
                                className="cursor-pointer"
                              />
                            ) : (
                              <VisibilityOffIcon
                                onClick={() =>
                                  handleClickShowPassword(true, "new_password")
                                }
                                className="cursor-pointer"
                              />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      sx={{ width: { xs: "100%", xl: "70%" } }}
                      onChange={({ target }) => {
                        // Filtrar solo números
                        const filteredValue = target.value.replace(/[^0-9]/g, "");
                        target.value = filteredValue;
                        setPassword(filteredValue);
                      }}
                      onKeyUp={(e) => {
                        if (
                          e.key === "Enter" &&
                          email.value &&
                          email.isValid &&
                          password.length >= 4
                        ) {
                          handleSigIn();
                        }
                      }}
                      inputProps={{
                        maxLength: 4, // Establece el máximo de caracteres aquí
                      }}
                    />
                    <ColorButton
                      variant="contained"
                      sx={{ fontSize: 15, width: { xs: "100%", sm: 200 } }}
                      disabled={
                        password.length < 4 ||
                        getUser.isLoading
                      }
                      onClick={handleSigIn}
                    >
                      Eliminar cuenta
                    </ColorButton>
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
