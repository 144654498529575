import { Box } from "@mui/material";
import React from "react";
import { sizes } from "../../styles/theme.tsx";

const PrivacyPolicies = () => {
    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                overflowX: 'hidden',
            }}
        >
            <Box
                sx ={{
                    flex: '1',
                    flexGrow: 1,
                    marginTop: `${sizes.header}px`,
                    paddingTop: '60px',
                    marginBottom: '100px',
                }}
            >
                <div className="p-6 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
                    <h1 className="text-2xl font-bold text-center mb-4">
                        AUTORIZACIÓN PARA EL TRATAMIENTO DE DATOS PERSONALES DE FINOVA APP 
                    </h1>
                    <p className="text-gray-700 text-sm mb-2">Última actualización: Febrero 4 de 2021</p>
                    <p className="text-gray-800 mb-4">
                        ¡TE DAMOS LA BIENVENIDA!
                    </p>
                    <p className="text-gray-700 mb-4">
                        El Cliente autoriza a FINOVA APP en calidad de responsable del tratamiento de datos personales, de manera previa, expresa e informada, a que trate los datos personales suministrados en la solicitud de las tarjetas de crédito de FINOVA APP APPo los que llegue a suministrar, de acuerdo con la Política de Tratamiento de Datos Personales. El Cliente, en su calidad de titular de los datos personales, podrá consultar la Política de Tratamiento de Datos Personales en cualquier momento a través de la siguiente página web <a href="https://finovaapp.com/terms" className="text-blue-500 underline">https://finovaapp.com/terms</a>, conforme a la Ley 1581 de 2012 y a la Ley 1266 de 2008, sus decretos reglamentarios y cualquier otra norma que las modifique, adicione o sustituya de tiempo en tiempo.
                    </p>
                    <h2 className="text-xl font-semibold mt-4 mb-2">Finalidades del tratamiento de datos:</h2>
                    <ul className="list-disc pl-6 text-gray-700 space-y-2">
                        <li>Consultar información en CIFIN y demás centrales de riesgos.</li>
                        <li>Reportar a centrales de riesgo el cumplimiento de obligaciones crediticias.</li>
                        <li>Recabar y almacenar datos personales en bases de datos de FINOVA APP.</li>
                        <li>Utilizar datos en campañas de comunicación, divulgación y promoción.</li>
                        <li>Conservar registros históricos y mantener contacto con los titulares.</li>
                        <li>Analizar información para mejorar productos y servicios.</li>
                        <li>Control y prevención del fraude y lavado de activos.</li>
                        <li>Celebración y gestión de contratos relacionados con servicios prestados.</li>
                        <li>Enviar comunicaciones a través de diferentes canales.</li>
                        <li>Realizar transferencias nacionales e internacionales de datos.</li>
                    </ul>
                    <h2 className="text-xl font-semibold mt-4 mb-2">Derechos del titular de datos:</h2>
                    <ul className="list-disc pl-6 text-gray-700 space-y-2">
                        <li>Conocer, actualizar y rectificar sus datos personales.</li>
                        <li>Solicitar prueba de autorización otorgada a FINOVA APP.</li>
                        <li>Ser informado sobre el uso de sus datos.</li>
                        <li>Presentar quejas ante la Superintendencia de Industria y Comercio.</li>
                        <li>Revocar la autorización del tratamiento de datos.</li>
                        <li>Acceder gratuitamente a sus datos en la base de datos de FINOVA APP.</li>
                    </ul>
                    <p className="mt-4 text-gray-700">
                        Para ejercer estos derechos, el Cliente puede comunicarse al correo: <a href="mailto:finovacomercial@gmail.com" className="text-blue-500 underline">finovacomercial@gmail.com</a>.
                    </p>
                </div>
            </Box>
        </Box>
    );
};

export default PrivacyPolicies;
