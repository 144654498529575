import React, { useContext, useEffect, useState } from "react";
import { Autocomplete, Box, Typography } from "@mui/material";
import { colors } from "../../../styles/theme.tsx";
import { ColorButton } from "../../../components/Button.tsx";
import "../../../index.css";
import InputField from "../../../components/InputField.tsx";
import { useFormik } from "formik";
import Grid from "@mui/material/Grid2";
import { IPanelAll, IReferencesForm, IUserAuth } from "../../../constants/types.tsx";
import {
  getValueStorage,
  saveValueStorage,
  wipeValueStorage,
} from "../../../common/storage.ts";
import { AuthContext } from "../../../hooks/useAuth.tsx";
import { requestSquematic } from "../../../common/CommonFunctions.tsx";
import i18n from "../../../constants/translations/i18n.js";
import { yupReferencesValidations } from "../../../common/validations/yupReferencesValidations.ts";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IRegisterStep } from "../RegisterComponent.tsx";

interface IReferences {
  isFamiliar: boolean;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
}

export default function References({ isFamiliar, setRegisterStep }: IReferences) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dataPanel: IPanelAll = getValueStorage("panelData");
  const { getUser, signIn, setIsLoading } = useContext(AuthContext);

  const [departmentList, setDepartmentList] = useState<any[]>([]);
  const [selectedDepartm, setSelectedDepartm] = useState<any | null>(null);

  const [cityList, setCityList] = useState<any[]>([]);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);

  const [relationship, setRelationship] = useState<any[]>([]);

  useEffect(() => {
    if (isFamiliar) wipeValueStorage("registerProcessData");
    fetchDepartments();
    setRelationship(
      dataPanel.all_labels_relationship.map((item: any) => ({
        id: item.c006_valor + "",
        value: item.c006_descripcion,
      }))
    );
  }, []);

  const fetchDepartments = async () => {
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/geographic_location/get_departments",
      {
        c102_id_pais: "169",
      },
      getUser.token
    );

    if (data && data.results) {
      setDepartmentList(
        data
          .results!.map((departments: any) => ({
            id: departments.c102_id,
            value: departments.c102_descripcion,
          }))
          .sort((a: any, b: any) => a.value.localeCompare(b.value))
      );
    }
    setIsLoading(false);
  };

  const fetchCities = async (departmentId: string) => {
    formik.setFieldValue("department", departmentId);
    setIsLoading(true);

    const data = await requestSquematic(
      "GET",
      "/api/panel/geographic_location/get_cities",
      {
        c103_id_pais: "169",
        c103_id_depto: departmentId,
      },
      getUser.token
    );

    if (data) {
      if (data.results.length === 0) {
        // No hay ciudades disponibles
        setCityList([]);
      } else {
        setCityList(
          data.results!.map((city: any) => ({
            id: city.c103_id,
            value: city.c103_descripcion,
          }))
        );
      }
    }

    setIsLoading(false);
  };

  // Mapea las opciones
  const relationshipOptions = relationship.map((item) => ({
    value: item.id + "",
    label: item.value,
  }));

  // Inicializar variables
  const formik = useFormik({
    initialValues: {
      phone: "",
      name: "",
      lastname: "",
      state_refences: isFamiliar,
      relationship: "",
      address: "",
      department: "",
      city: "",
    },
    validationSchema: yupReferencesValidations,
    onSubmit: (values) => {
      if (isFamiliar) {
        saveValueStorage("registerProcessData", values);
        let obj = {
          prevStep: 8,
          nextStep: 9,
          desc: "se crea la informacion de referencia familiar",
        };
        saveValueStorage("registerStep", obj);
        if (setRegisterStep) setRegisterStep(obj);
      } else {
        handleSubmit(values);
      }
    },
  });

  //Enviar a alamcenar la informacion de las referencias
  const handleSubmit = async (personalReference: IReferencesForm) => {
    setIsLoading(true);
    const familiarReference: IReferencesForm = getValueStorage(
      "registerProcessData"
    );
    const objToSend = {
      referenciaPersonal: JSON.stringify({
        c125_contacto: `personal-${personalReference.name} ${personalReference.lastname}`,
        c125_direccion1: personalReference.address,
        c125_id_pais: "169",
        c125_id_depto: String(personalReference.department),
        c125_id_ciudad: String(personalReference.city),
        c125_cod_postal: "+57",
        c125_celular: personalReference.phone,
      }),
      referenciaFamiliar: JSON.stringify({
        c125_contacto: `familiar-${familiarReference.name} ${familiarReference.lastname}`,
        c125_direccion1: familiarReference.address,
        c125_id_pais: "169",
        c125_id_depto: String(familiarReference.department),
        c125_id_ciudad: String(familiarReference.city),
        c125_cod_postal: "+57",
        c125_celular: familiarReference.phone,
        c125_rowid_parentesco: familiarReference.relationship,
      }),
      c015_direccion_ip: "1",
      platform: 'web'
    };

    const data = await requestSquematic(
      "POST",
      "/api/app/contact_details/create_refactor",
      objToSend,
      getUser.token
    );

    if (data) {
      const authSession: IUserAuth = getValueStorage("authSession");
      signIn({ ...authSession, hasReferences: true });
      saveValueStorage("authSession", { ...authSession, hasReferences: true });
      wipeValueStorage("registerProcessData");
      let obj = {
        prevStep: 9,
        nextStep: 10,
        desc: "se guarda la informacion de las referencias",
      };
      saveValueStorage("registerStep", obj);
      if (setRegisterStep) setRegisterStep(obj);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // Si hay solo cambios en el departamento, se borra la ciudad y su error
    setSelectedCity(null);
  }, [formik.values.department]);

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.black,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.form")}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: 20,
            color: colors.primary,
            textTransform: "uppercase",
            marginBottom: "5px",
          }}
          lineHeight={1}
          textAlign={"center"}
        >
          {t("common.forRequestCredit")}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection={"column"} alignItems={"left"} mt={5}>
        <Box>
          <Box style={{ color: colors.darkGray, fontSize: 13 }}>
            Completa todos los campos
          </Box>
          <Box
            className="text-primary"
            style={{
              fontSize: 15,
              fontWeight: 700,
              marginTop: 0,
              color: colors.primary,
              textTransform: "uppercase",
              lineHeight: 1,
            }}
          >
            {isFamiliar
              ? "AGREGA UNA REFERENCIA FAMILIAR"
              : "AGREGA UNA REFERENCIA PERSONAL"}
          </Box>
        </Box>
        <Grid container spacing={2} columns={12} mt={2.5}>
          <Grid size={{ xs: 12, md: 6, xl: 4 }}>
            <InputField
              name="name"
              label="Nombre"
              formik={formik}
              maxLength={150}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6, xl: 4 }}>
            <InputField
              name="lastname"
              label="Apellido"
              formik={formik}
              maxLength={150}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, xl: 4 }}>
            <InputField
              name="phone"
              formik={formik}
              label="Celular"
              disabled={getUser.isLoading}
              maxLength={10}
              onKeyPress={(e) => {
                if (!/[0-9]/.test(e.key)) {
                  e.preventDefault(); // Prevenir la entrada si no es un número
                }
              }}
            />
          </Grid>
          <Grid
            size={{ xs: 12, sm: 6, xl: 6 }}
            sx={{ display: isFamiliar ? "block" : "none" }}
          >
            <InputField
              name="relationship"
              formik={formik}
              label="Parentesco"
              type="select"
              options={relationshipOptions}
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid
            size={{ xs: 12, sm: isFamiliar ? 12 : 6, xl: isFamiliar ? 6 : 12 }}
          >
            <InputField
              name="address"
              formik={formik}
              label="Dirección"
              disabled={getUser.isLoading}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Autocomplete
              options={departmentList}
              getOptionLabel={(option) => option.value}
              value={selectedDepartm}
              onChange={(event, newValue) => {
                setSelectedDepartm(newValue);
                formik.setFieldValue("city", "");
                fetchCities(newValue ? newValue.id + "" : "");

                // Si se selecciona una opción válida, actualizar Formik; si no, establecer en cadena vacía
                formik.setFieldValue(
                  "department",
                  newValue ? newValue.id + "" : ""
                );
              }}
              disabled={getUser.isLoading || !departmentList.length}
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = departmentList.find(
                  (option) => option.value === inputValue
                );
                if (!matchedOption) {
                  formik.setFieldValue("department", "");
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <InputField
                  {...params}
                  name="department"
                  formik={formik}
                  noFormik
                  label={t("contactInformation.department")}
                  disabled={getUser.isLoading || !departmentList.length}
                  placeholder={departmentList.length === 0 ? "" : "Seleccione"}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !!selectedDepartm, // Si hay una selección, el campo será de solo lectura
                  }}
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Autocomplete
              options={cityList}
              getOptionLabel={(option) => option.value}
              value={selectedCity}
              onChange={(event, newValue) => {
                setSelectedCity(newValue);
                // Si se selecciona una opción válida, actualizar Formik; si no, establecer en cadena vacía
                formik.setFieldValue("city", newValue ? newValue.id + "" : "");
              }}
              disabled={getUser.isLoading || !cityList.length}
              onInputChange={(event, inputValue) => {
                // Si el valor ingresado no tiene una coincidencia, limpiar el campo en Formik
                const matchedOption = cityList.find(
                  (option) => option.value === inputValue
                );
                if (!matchedOption) {
                  formik.setFieldValue("city", "");
                }
              }}
              noOptionsText={t("common.empty")}
              renderInput={(params) => (
                <InputField
                  {...params}
                  name="city"
                  formik={formik}
                  noFormik
                  label={t("contactInformation.city")}
                  disabled={getUser.isLoading || !cityList.length}
                  placeholder={cityList.length === 0 ? "" : "Seleccione"}
                  inputProps={{
                    ...params.inputProps,
                    readOnly: !!selectedCity, // Si hay una selección, el campo será de solo lectura
                  }}
                />
              )}
            />
          </Grid>
        </Grid>

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          marginTop={8}
        >
          <ColorButton
            sx={{
              width: "fit-content",
              padding: "15px 40px",
              fontSize: 16,
            }}
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={getUser.isLoading}
          >
            Continuar
          </ColorButton>
        </Box>
      </Box>
    </>
  );
}
