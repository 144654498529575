import React from "react";
import {
  Box,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Slider,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  IParseRenovation,
  IPaymentPlan,
  ISimulatorData,
  loanResponse,
  mockSelectBasic,
  navigationModals,
} from "../../constants/types";
import { colors } from "../../styles/theme.tsx";
import {
  aproxValueWithZeros,
  formatNumberPoint,
  requestSquematic,
} from "../../common/CommonFunctions.tsx";
import {
  getValueStorage,
  wipeValueStorage,
} from "../../common/storage.ts";
import {
  errorAlert,
  Modal,
  ModalDashboard,
  MySwal,
} from "../../common/alerts.tsx";
import { KeyboardBackspaceIcon } from "../../pages/Dashboard/assets/iconsMui.tsx";
import { AuthContext } from "../../hooks/useAuth.tsx";
import { IRegisterStep } from "../../pages/User/RegisterComponent.tsx";
import { HasOfferInitial } from "../../pages/Dashboard/pages/Reoffer/HasOfferInitial.tsx";
import HasOfferConfirmLoan from "../../pages/Dashboard/pages/Reoffer/HasOfferConfirmLoan.tsx";

const CustomSlider = styled(Slider)({
  color: colors.primary,
});

const minVals = {
  1: 500000,
  4: 500000,
  5: 500000,
};

interface IOfferSimulator {
  setIsLoading: (isLoading: boolean) => void;
  onClose?: any;
  parseRenovacion?: () => any;
  setRegisterStep?: React.Dispatch<React.SetStateAction<IRegisterStep>>;
  parseRenovacionCustom?: IParseRenovation;
  localData?: loanResponse & { name: string };
}

export default function HasOfferSimulator({
  setIsLoading,
  onClose,
  localData,
}: IOfferSimulator) {
  const { signOut } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const params = getValueStorage("HasOfferSimulation");
  const [data, setData] = React.useState<ISimulatorData>({
    c200_rowid: params.c200_rowid_new,
    c200_valor: params.maxAmount,
    c200_rowid_ciiu: params.personType,
    c200_plazo: params.dues, // semanal: 4, 8, 12, 16 - quincenal: 2, 4, 6, 8
    c200_rowid_concepto: params.creditLine,
    c200_rowid_periodo: params.periodPay, //1 semanal, 4 quincenal
    date_first_due: "",
    due_value: "0",
    total_due_to_pay: params.totalbalancedue,
  });

  const minValue = React.useMemo(
    () =>
      params?.c200_renovacion === "1"
        ? aproxValueWithZeros(parseInt(params.totalbalancedue) * 2, 6)
        : minVals[params?.periodPay as keyof object],
    []
  );

  React.useEffect(() => {
    // Función para manejar el evento de recarga o cierre
    const handleBeforeUnload = (event) => {
      // Evita la acción predeterminada
      event.preventDefault();
      event.returnValue = "";
    };

    // Agrega el listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Limpia el listener al desmontar el componente
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      await fetchSubcategories();
    })();
  }, []);

  const [arrPlazo, setArrPlazo] = React.useState<mockSelectBasic[]>([]);
  const [dueValue, setDueValue] = React.useState<string>("0");
  const [valSlider, setValSlider] = React.useState({
    min: minValue,
    max: parseFloat(params.maxAmount),
    frecuency: 100000,
  });

  // Actualizar el token
  const token = getValueStorage("authSession").token;

  //Obtener la configuracion de la linea de credito
  const fetchSubcategories = async () => {
    try {
      const res = await requestSquematic(
        "POST",
        "/api/app/sub_categories/find",
        { c128_rowid_concepto: data.c200_rowid_concepto },
        token,
        signOut
      );

      if (res && res.results) {
        const min = minValue;
        const max = parseFloat(params.maxAmount);
        const frecuency = parseFloat(res.results![0].c128_incremento);
        const cantDues = parseFloat(res.results![0].c128_nro_cuotas);
        const amount = parseInt(data.c200_valor);

        setValSlider({ frecuency, min, max });

        handleChangePeriodicity(data.c200_rowid_periodo, cantDues + "");

        await handlerSimulator(
          amount,
          cantDues,
          parseInt(data.c200_rowid_periodo)
        );
      }
    } catch (error) {
      errorAlert(
        "Ocurrió un error al simular el préstamo.",
        " Por favor, inténtalo de nuevo.",
        ""
      );
    }
  };

  //Hace la simulacion del credito segun los parametros seleccionados
  const handlerSimulator = async (
    amount: number,
    cantDues: number,
    period: number
  ) => {
    if (amount === 0) return true;
    setIsLoading(true);

    try {
      const res = await requestSquematic(
        "POST",
        "/api/app/loan/loan_simulate",
        {
          c200_valor: amount,
          c200_rowid_concepto: data.c200_rowid_concepto,
          c200_plazo: cantDues,
          c200_rowid_periodo: period,
          c200_rowid_ciiu: data.c200_rowid_ciiu,
          is_simulate_website: "true", // Para no guardar la simulación en la base de datos
        },
        token,
        signOut
      );

      if (res && res.results) {
        const dueValue = res.results.additional_payment_plan.reduce(
          (sum, { c210_vlr_cuota }) => sum + parseInt(c210_vlr_cuota),
          parseInt(res.results.payment_plan[0].c202_vlr_cuota)
        );
        setDueValue(dueValue + "");

        // Extraer la cantidad de cuotas y organizarlas de menor a mayor para obtener la primera fecha de pago
        const duesToPay = res.results.payment_plan.sort(
          (a: IPaymentPlan, b: IPaymentPlan) =>
            parseInt(a.c202_rowid) - parseInt(b.c202_rowid)
        );

        // Calcular el valor total a pagar
        const totalDuesCapital = duesToPay.reduce(
          (sum, { c202_vlr_cuota }) => sum + parseInt(c202_vlr_cuota),
          0
        );
        const totalDuesInt = res.results.additional_payment_plan.reduce(
          (sum, { c210_vlr_cuota }) => sum + parseInt(c210_vlr_cuota),
          0
        );

        setData({
          ...data,
          c200_rowid_periodo: period + "",
          c200_valor: amount + "",
          c200_plazo: cantDues + "",
          date_first_due: duesToPay[0].c202_fecha_cuota,
          due_value: dueValue + "",
          total_due_to_pay: totalDuesCapital + totalDuesInt * cantDues + "",
        });
      }
    } catch (error) {
      errorAlert(
        "Ocurrió un error al simular el préstamo.",
        " Por favor, inténtalo de nuevo.",
        ""
      );
    } finally {
      setIsLoading(false);
    }
  };

  //Cambiar el valor del monto a solicitar
  const handleChangeDueValue = (newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setData({ ...data, c200_valor: newValue.toString() });
      handlerSimulator(
        newValue,
        parseInt(data.c200_plazo),
        parseInt(data.c200_rowid_periodo)
      );
    }
  };

  //Cmabiar la periodicidad de pago
  const handleChangePeriodicity = (periodo: string, plazo: string) => {
    handlerSimulator(
      parseInt(data.c200_valor),
      parseInt(plazo),
      parseInt(periodo)
    );
    if (periodo === "1") {
      setArrPlazo([
        { id: "4", name: "4" },
        { id: "8", name: "8" },
        { id: "12", name: "12" },
        { id: "16", name: "16" },
      ]);
    } else {
      setArrPlazo([
        { id: "2", name: "2" },
        { id: "4", name: "4" },
        { id: "6", name: "6" },
        { id: "8", name: "8" },
      ]);
    }
  };

  //Cambiar la cantidad de cuotas
  const handleChangeCantDues = (cantDues: string) => {
    setData({ ...data, c200_plazo: cantDues });
    handlerSimulator(
      parseInt(data.c200_valor),
      parseInt(cantDues),
      parseInt(data.c200_rowid_periodo)
    );
  };

  //Tomar los datos de la simulacion y enviarlos al ConfirmLoan
  const handlePreSubmitSimulation = () => {
    MySwal.close();
    Modal(
      <HasOfferConfirmLoan params={data} onClose={onClose} setIsLoading={setIsLoading} />,
      {
        title: "",
        width: "auto",
        showCloseButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        customClass: {
          popup: "bg-snow dark:bg-navy-800 px-0 !w-max sm:!w-[380px]",
          htmlContainer: "!px-3 !overflow-x-hidden",
        },
      }
    );
  };

  // Volver al ChooseCredit y cerrar el modal actual
  const handleBack = async () => {
    MySwal.close();
    wipeValueStorage("HasOfferSimulation");
    ModalDashboard({
      element: (
        <HasOfferInitial setIsLoading={setIsLoading} localData={localData} />
      ),
      close: false,
    });
  };

  return (
    <>
      <div className="relative">
        <button
          className="flex flex-row gap-1 items-center text-secondary-800 hover:opacity-80 dark:text-primary  "
          onClick={handleBack}
        >
          <KeyboardBackspaceIcon />
          <p className="text-sm ">{t("common.back")}</p>
        </button>
      </div>

      <div className="w-[98%] pt-3 overflow-x-hidden mx-auto px-3 relative text-center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: 25,
              color: colors.primary,
            }}
            lineHeight={1}
            textAlign={"center"}
          >
            {t("home.simulatorTitle").split("-")[0]} <br />
            <span style={{ color: colors.primary, fontWeight: "bold" }}>
              {t("home.simulatorTitle").split("-")[1]}
            </span>
          </Typography>

          {/* Deslizador de monto */}
          <Box my={6} width={"100%"}>
            <Typography
              className="text-secondary-800	dark:text-white"
              sx={{ fontSize: 20 }}
            >
              {t("simulator.slide")}
            </Typography>
            {/* Deslizador */}
            <div style={{ paddingLeft: 12, paddingRight: 12, width: "100%" }}>
              <CustomSlider
                value={parseInt(data.c200_valor)}
                min={valSlider.min}
                step={valSlider.frecuency}
                max={valSlider.max}
                onChange={(
                  event: React.SyntheticEvent | Event,
                  value: number | number[]
                ) => setData({ ...data, c200_valor: value + "" })}
                onChangeCommitted={(
                  event: React.SyntheticEvent | Event,
                  value: number | number[]
                ) => handleChangeDueValue(value)}
              />
            </div>
          </Box>

          <Typography
            className="text-secondary-800	dark:text-white"
            sx={{
              fontSize: 20,
              mb: 0.5,
              lineHeight: "1",
              fontWeight: "bold",
            }}
          >
            Valor préstamo
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: 28, sm: 30, md: 33 },
              fontWeight: "bold",
              color: colors.primary,
              lineHeight: "1",
            }}
          >
            ${formatNumberPoint(data.c200_valor)}
          </Typography>

          {/* METODO DE PAGO */}
          <Box
            sx={{ width: { xs: "100%", sm: "300px" }, alignItems: "center" }}
          >
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Typography
                sx={{
                  fontSize: 18,
                  color: colors.darkSlateGray,
                  marginTop: 5,
                }}
              >
                Periodicidad
              </Typography>
            </Box>

            <FormControl sx={{ width: { xs: "100%", sm: "300px" } }}>
              <Select
                labelId="demo-simple-select-label"
                value={data.c200_rowid_periodo}
                disabled
                onChange={({ target }) =>
                  handleChangePeriodicity(
                    target.value,
                    target.value === "1" ? "4" : "2"
                  )
                }
                sx={{
                  color: colors.black,
                  width: "100%",
                  textAlign: "center",
                  borderRadius: 2,
                  fontSize: { xs: "13px", md: "16px" },
                }}
              >
                {/* <MenuItem value="1">{t("simulator.paymentMethod1")}</MenuItem> */}
                <MenuItem value="4">{t("simulator.paymentMethod2")}</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* CANTIDAD DE CUOTAS */}
          <Box
            sx={{ width: { xs: "100%", sm: "300px" }, alignItems: "center" }}
          >
            <Box sx={{ textAlign: "left", width: "100%" }}>
              <Typography
                sx={{
                  fontSize: 18,
                  color: colors.darkSlateGray,
                  marginTop: 1.5,
                }}
              >
                Plazo
              </Typography>
            </Box>
            <FormControl sx={{ width: { xs: "100%", sm: "300px" } }}>
              <Select
                labelId="demo-simple-select-label"
                value={data.c200_plazo}
                onChange={({ target }) =>
                  handleChangeCantDues(target.value as string)
                }
                sx={{
                  color: colors.black,
                  width: "100%",
                  textAlign: "center",
                  borderRadius: 2,
                  fontSize: { xs: "13px", md: "16px" },
                }}
              >
                {arrPlazo.map((item) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Box>

          {/* VALOR A PAGAR POR CUOTA */}
          <Box sx={{ mt: 5, mb: 6 }}>
            <Typography
              sx={{
                fontSize: { xs: 28, sm: 30, md: 35 },
                fontWeight: "bold",
                color: colors.primary,
                lineHeight: "1",
              }}
            >
              {dueValue !== "0" ? (
                `$${formatNumberPoint(dueValue)}`
              ) : (
                <CircularProgress color="success" />
              )}
            </Typography>
            <Typography
              className="text-black dark:text-secondary-600"
              sx={{
                fontSize: 20,
                fontWeight: "bold",
                lineHeight: "1",
              }}
            >
              Valor de cuota
            </Typography>
          </Box>

          <button
            className="w-64 rounded-lg bg-primary py-3 px-2 text-white font-bold text-base hover:opacity-80 "
            onClick={() => handlePreSubmitSimulation()}
          >
            {t("common.continue")}
          </button>
        </Box>
      </div>
    </>
  );
}
