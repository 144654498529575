import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className="flex flex-col items-center justify-center h-screen">
        <h1 className="text-4xl font-bold text-gray-800 dark:text-white">404</h1>
        <p className="text-lg text-gray-600 dark:text-white">{t("common.notFount")}</p>
            <button
                onClick={() => navigate("/")}
                className="mt-2 uppercase w-fit leading-4 rounded-lg py-3 px-8 sm:px-16 text-white font-bold text-base hover:opacity-75 bg-primary"
            >
                {t("common.goBack")}
            </button>
        </div>
    );
};

export default NotFound;